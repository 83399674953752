import { SectionHeader } from "app/components/sectionHeader";
import * as React from "react";
import { useBranding } from "./useBranding";
import { PlusIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export interface BrandingProps {}

export const Branding: React.FC<BrandingProps> = (props) => {
  const {
    handleLogoChange,
    handleLandingImagesChange,
    saveSelectedImage,
    logoPreview,
    landingImagesPreviews,
    account,
  } = useBranding();

  return (
    <div className="flex flex-col w-full gap-6">
      <SectionHeader title="Branding" />

      {/* Logo Section */}
      <div className="p-4 gap-2 flex flex-col rounded border border-[#E4E4E7]">
        <p className="text-sm text-zinc-500">Logo</p>
        <p className="text-sm ">
          This will be displayed on your booking page, emails. Image must be at
          least 80 pixels high. Accepted file types: .jpg and .png
        </p>
        <div className="flex items-center gap-4">
          {/* Preview of logo */}
          {logoPreview && (
            <img
              src={logoPreview}
              alt="Logo Preview"
              className="h-[100px] w-[100px] rounded border"
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleLogoChange}
            className="hidden"
            id="logo-upload"
          />
          <label
            htmlFor="logo-upload"
            className="flex flex-col gap-3 w-[100px] h-[100px] bg-zinc-100 items-center justify-center border-dashed border-2 p-[10px] rounded cursor-pointer"
          >
            <PlusIcon className="h-5 w-5 stroke-black" />
            <p className=" text-sm">Upload</p>
          </label>
        </div>
      </div>

      {/* Landing Images Section */}
      <div className="p-4 gap-2 flex flex-col rounded border border-[#E4E4E7]">
        <p className="text-sm text-zinc-500">Landing image</p>
        <p className="text-sm">
          This will be displayed on your booking page. Upload your own or choose
          one from our library. Accepted file types: .jpg and .png
        </p>
        <div className="flex gap-4">
          {/* Preview of landing images */}
          {landingImagesPreviews?.map((src: string, index: number) => (
            <div
              key={index}
              className={`relative cursor-pointer duration-300 ${
                account.selectedLandingImageLink === src
                  ? "border-2 border-success-500 rounded-sm "
                  : "hover:scale-110"
              }`}
              onClick={() => saveSelectedImage(src)}
            >
              {account.selectedLandingImageLink === src && (
                <CheckCircleIcon className="absolute top-1 right-1 h-7 w-7 stroke-success-500 fill-success-100" />
              )}
              <img
                src={src}
                alt={`Landing image preview ${index}`}
                className="h-[100px] w-[100px] rounded-sm"
              />
            </div>
          ))}
          <input
            type="file"
            accept="image/*"
            onChange={handleLandingImagesChange}
            className="hidden"
            id="landing-images-upload"
            multiple
          />
          <label
            htmlFor="landing-images-upload"
            className="flex flex-col gap-3 w-[100px] h-[100px] bg-zinc-100 items-center justify-center border-dashed border-2 p-[10px] rounded cursor-pointer"
          >
            <PlusIcon className="h-5 w-5 stroke-black" />
            <p className=" text-sm">Upload</p>
          </label>
        </div>
      </div>
    </div>
  );
};
