import React, { useState, useEffect, useRef } from "react";
import { motion, PanInfo, useMotionValue, animate } from "framer-motion";
import { format, isSameDay, addDays } from "date-fns";
import { ScheduleDateChip } from "app/subframe/components/ScheduleDateChip";

interface SwipeableWeekViewProps {
  currentWeekStart: Date;
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  onWeekChange: (newWeekStart: Date) => void;
}

export const SwipeableWeekView: React.FC<SwipeableWeekViewProps> = ({
  currentWeekStart,
  selectedDate,
  onDateChange,
  onWeekChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  // Update width when container resizes
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  // Motion value for x position
  const x = useMotionValue(-width);

  // Index to keep track of the position (0: previous week, 1: current week, 2: next week)
  const [index, setIndex] = useState(1);

  // Generate weeks based on currentWeekStart
  const weeks = React.useMemo(() => {
    const prevWeekStart = addDays(currentWeekStart, -7);
    const nextWeekStart = addDays(currentWeekStart, 7);
    return [
      { start: prevWeekStart, key: `prev-${prevWeekStart.toISOString()}` },
      {
        start: currentWeekStart,
        key: `current-${currentWeekStart.toISOString()}`,
      },
      { start: nextWeekStart, key: `next-${nextWeekStart.toISOString()}` },
    ];
  }, [currentWeekStart]);

  // Update x position when index or width changes
  useEffect(() => {
    x.set(-width * index);
  }, [index, width, x]);

  // Reset x position when currentWeekStart changes
  useEffect(() => {
    x.set(-width * 1); // Center position
  }, [currentWeekStart, width, x]);

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const offset = info.offset.x;
    const velocity = info.velocity.x;

    let newIndex = index;
    let newWeekStart = currentWeekStart;

    if (offset < -width / 4 || (offset < 0 && velocity < -500)) {
      // Swipe left (to next week)
      newIndex = index + 1;
    } else if (offset > width / 4 || (offset > 0 && velocity > 500)) {
      // Swipe right (to previous week)
      newIndex = index - 1;
    }

    // Clamp newIndex between 0 and 2
    newIndex = Math.max(0, Math.min(newIndex, 2));

    const targetX = -width * newIndex;

    // Animate to the target position
    animate(x, targetX, {
      type: "spring",
      stiffness: 300,
      damping: 30,
      onComplete: () => {
        if (newIndex !== index) {
          // Update index
          setIndex(1); // Reset index to center

          // Update currentWeekStart
          if (newIndex === 0) {
            // Moved to previous week
            newWeekStart = addDays(currentWeekStart, -7);
          } else if (newIndex === 2) {
            // Moved to next week
            newWeekStart = addDays(currentWeekStart, 7);
          }

          onWeekChange(newWeekStart);
        } else {
          // If index didn't change, ensure x is back to current position
          x.set(-width * index);
        }
      },
    });
  };

  return (
    <div ref={containerRef} className="overflow-hidden w-full">
      <motion.div
        drag="x"
        style={{ x }}
        dragConstraints={{ left: -width * 2, right: 0 }}
        dragElastic={0.2}
        onDragEnd={handleDragEnd}
        className="flex"
      >
        {weeks.map((week) => {
          const weekDays = Array.from({ length: 7 }, (_, i) =>
            addDays(week.start, i)
          );
          return (
            <div
              key={week.key}
              className="flex-none w-full flex justify-between px-2"
            >
              {weekDays.map((date) => {
                const isSelected = isSameDay(selectedDate, date);
                return (
                  <ScheduleDateChip
                    key={date.toISOString()}
                    text={format(date, "EEE")}
                    text2={format(date, "d")}
                    isSelected={isSelected}
                    onClick={() => onDateChange(date)}
                  />
                );
              })}
            </div>
          );
        })}
      </motion.div>
    </div>
  );
};
