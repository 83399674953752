// NewLocationModal.tsx

import React from "react";
import {
  Button,
  Listbox,
  ListboxItem,
  ListboxSection,
} from "@nextui-org/react";
import { AsIconButton, DialogLayout, AsInput } from "app/subframe";
import PhoneInput from "react-phone-input-2";
import { useNewLocationModal } from "./useNewLocationModal";

export interface NewLocationModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const NewLocationModal: React.FC<NewLocationModalProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const {
    addressInputText,
    locationSuggestions,
    onLocationInputChange,
    onLocationSelect,
    isSubmitting,
    onSubmit,
    setPhoneValue,
    phoneValue,
  } = useNewLocationModal({ onOpenChange });

  return (
    <DialogLayout
      open={isOpen}
      onOpenChange={onOpenChange}
      className="z-[1000]"
    >
      <div className="flex h-auto w-144 max-w-md flex-col items-center gap-4 rounded-lg mobile:w-full bg-default-background px-6 py-6 shadow-dialog-shadow">
        <div className="flex w-full items-center justify-between">
          <h2 className="text-lg font-bold text-neutral-900">
            Add New Location
          </h2>
          <AsIconButton
            className="h-8 w-8 flex-none"
            variant="ghost"
            leadingIcon="FeatherX"
            trailingIcon={null}
            text="Close"
            size="default"
            onClick={() => onOpenChange(false)}
          />
        </div>
        <form className="flex flex-col gap-6 w-full" onSubmit={onSubmit}>
          {/* Location Name */}
          <div className="flex flex-col gap-2">
            <label className="text-[#71717A] text-sm">Location name</label>
            <AsInput
              name="name"
              placeholder="West End"
              required
              textPosition="left"
            />
          </div>

          {/* Address Input with Suggestions */}
          <div className="flex flex-col gap-2 relative">
            <label className="text-[#71717A] text-sm">Address</label>
            <AsInput
              name="address"
              placeholder="100 Main St, New York, NY 10001"
              value={addressInputText}
              onChange={(e) => onLocationInputChange(e.target.value)}
              required
              textPosition="left"
            />
            {locationSuggestions.length > 0 && (
              <Listbox
                className="absolute top-16 z-30 w-full"
                classNames={{
                  base: "!bg-white rounded shadow-sm",
                }}
                onAction={(key: any) => onLocationSelect(key)}
              >
                <ListboxSection title="Suggestions" showDivider>
                  {locationSuggestions.map((location) => (
                    <ListboxItem
                      classNames={{
                        base: "hover:bg-[#E4E4E4] rounded-sm",
                      }}
                      key={location.name}
                      textValue={location.name}
                    >
                      {location.name}
                    </ListboxItem>
                  ))}
                </ListboxSection>
              </Listbox>
            )}
          </div>

          {/* Contact Email */}
          <div className="flex flex-col gap-2">
            <label className="text-zinc-500 text-sm">Contact email</label>
            <AsInput
              name="email"
              type="email"
              placeholder="you@company.com"
              required
              textPosition="left"
            />
          </div>

          {/* Contact Phone */}
          <div className="flex flex-col gap-2">
            <label className="text-zinc-500 text-sm">Contact phone</label>
            <PhoneInput
              country={"us"}
              value={phoneValue}
              specialLabel=""
              onChange={(value) => setPhoneValue(value)}
              inputStyle={{
                padding: "10px",
                filter:
                  "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))",
                width: "100%",
                fontSize: "14px",
                fontWeight: "600",
                border: "1px solid #F4F4F5",
                borderRadius: "4px",
              }}
              inputProps={{
                name: "phone",
                required: true,
              }}
            />
          </div>

          {/* Tax Percentage */}
          <div className="flex flex-col gap-2">
            <label className="text-zinc-500 text-sm">Tax Percentage (%)</label>
            <AsInput
              name="taxPercentage"
              type="number"
              placeholder="0.0"
              required
              textPosition="left"
            />
          </div>

          <Button
            variant="ghost"
            className="rounded-sm border border-black w-fit px-6 self-end"
            type="submit"
            isDisabled={isSubmitting}
          >
            {isSubmitting ? "Creating..." : "Create"}
          </Button>
        </form>
      </div>
    </DialogLayout>
  );
};
