import {
  createPaymentLink,
  updatePaymentLink,
} from "app/redux/paymentLinkSlice";
import { selectSales, updateSale } from "app/redux/salesSlice";
import { useQuery } from "app/utils/useQuery";
import { find, set } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export enum CreditCardOptions {
  PREPAID = "PREPAID",
  PAYMENT_LINK = "PAYMENT_LINK",
  QR_CODE = "QR_CODE",
  IN_APP = "IN_APP",
}

export const useCcCheckout = ({
  tipsCents,
  totalCents,
}: {
  tipsCents: number;
  totalCents: number;
}) => {
  const [creditCardOption, setCreditCardOption] =
    useState<CreditCardOptions | null>();
  const dispatch = useDispatch();
  const query = useQuery();
  const saleIdParam = query.get("saleId");
  const { sales } = useSelector(selectSales);
  const sale = find(sales, { id: Number(saleIdParam) });
  const [paymentLink, setPaymentLink] = useState<any>(
    sale?.latestPendingPaymentLink
  );
  const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false);
  const [isCreatingPaymentLink, setIsCreatingPaymentLink] =
    useState<boolean>(false);

  const handleCreatePaymentLink = async () => {
    setIsCreatingPaymentLink(true);
    try {
      const paymentLink = await dispatch(
        createPaymentLink(sale!.id) as any
      ).unwrap();
      setPaymentLink(paymentLink);
    } catch (error) {
      return console.log("error", error);
    } finally {
      setIsCreatingPaymentLink(false);
    }
  };

  // const syncPaymentLinkData = async () => {
  //   console.log("tipsCents", tipsCents);
  //   console.log("totalCents", totalCents);

  //   try {
  //     await dispatch(
  //       updateSale({
  //         id: sale!.id,
  //         tipsCents,
  //       }) as any
  //     ).unwrap();
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     showQrCodeModal ||
  //     creditCardOption === CreditCardOptions.QR_CODE ||
  //     creditCardOption === CreditCardOptions.PAYMENT_LINK
  //   ) {
  //     syncPaymentLinkData();
  //   }
  // }, [creditCardOption, showQrCodeModal]);

  useEffect(() => {
    if (!paymentLink) {
      handleCreatePaymentLink();
    }
  }, []);

  return {
    creditCardOption,
    setCreditCardOption,
    paymentLink,
    showQrCodeModal,
    isCreatingPaymentLink,
    setShowQrCodeModal,
    sale,
  };
};
