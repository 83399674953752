// useNewLocationModal.tsx

import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { createShop } from "app/redux/shopsSlice";
import { searchLocation as searchLocationFromApi } from "app/redux/mapboxSlice";
import { toast } from "react-toastify";
import { getFormValues } from "app/utils/getFormValues";

interface LocationSuggestion {
  name: string;
  latitude: number;
  longitude: number;
}

interface UseNewLocationModalProps {
  onOpenChange: (open: boolean) => void;
}

export const useNewLocationModal = ({
  onOpenChange,
}: UseNewLocationModalProps) => {
  const dispatch = useDispatch();

  const [addressInputText, setAddressInputText] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState<
    LocationSuggestion[]
  >([]);
  const [selectedLocation, setSelectedLocation] =
    useState<LocationSuggestion | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");

  const searchLocations = async (search: string) => {
    if (!search) {
      setLocationSuggestions([]);
      return;
    }
    try {
      const { data } = await dispatch(
        searchLocationFromApi({ query: search }) as any
      ).unwrap();
      setLocationSuggestions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearchLocations = useCallback(
    debounce((search: string) => {
      searchLocations(search);
    }, 300),
    []
  );

  const onLocationInputChange = (search: string) => {
    setAddressInputText(search);
    debouncedSearchLocations(search);
  };

  const onLocationSelect = (locationName: string) => {
    const selected = locationSuggestions.find(
      (loc) => loc.name === locationName
    );
    if (selected) {
      setSelectedLocation(selected);
      setAddressInputText(selected.name);
      setLocationSuggestions([]);
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formValues = getFormValues(event.target as HTMLFormElement);
    const { email, name, taxPercentage, address } = formValues;

    const phone = phoneValue;

    if (!email || !phone || !name || !address || !taxPercentage) {
      toast.error("Please fill in all fields");
      return;
    }

    if (phone.length < 11) {
      toast.error("Phone number doesn't seem to be right");
      return;
    }

    // Validate taxPercentage
    const taxPercentageValue = parseFloat(taxPercentage);
    if (
      isNaN(taxPercentageValue) ||
      taxPercentageValue < 0 ||
      taxPercentageValue > 100
    ) {
      toast.error("Please enter a valid tax percentage between 0 and 100");
      return;
    }

    setIsSubmitting(true);

    const addressLine1 = address.split(",")[0];
    const addressLine2 = address.split(",").slice(1).join(",").trim();

    let latitude = 0;
    let longitude = 0;

    if (selectedLocation && selectedLocation.name === address) {
      latitude = selectedLocation.latitude;
      longitude = selectedLocation.longitude;
    }

    try {
      await dispatch(
        createShop({
          name,
          email,
          phone,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          latitude,
          longitude,
          taxPercentage: taxPercentageValue, // Include taxPercentage in the payload
        }) as any
      ).unwrap();

      toast.success(`Location: ${name} created successfully`);
      onOpenChange(false); // Close the modal
    } catch (error) {
      console.log(error);
      toast.error("Failed to create new location");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    addressInputText,
    locationSuggestions,
    onLocationInputChange,
    onLocationSelect,
    isSubmitting,
    onSubmit,
    setPhoneValue,
    phoneValue,
  };
};
