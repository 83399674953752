import { Button, ModalBody, ModalHeader } from "@nextui-org/react";
import { AvailabilityTypes } from "app/redux/availabilitiesSlice";
import { format, parseISO } from "date-fns";
import * as React from "react";

export interface IntentScreenProps {
  setScreenIndex: (index: number) => void;
  availability?: any;
  setAvailabilityEditingIntent: (intent: AvailabilityTypes) => void;
  screenIndex: number;
}

export const IntentScreen: React.FC<IntentScreenProps> = ({
  setScreenIndex,
  availability,
  setAvailabilityEditingIntent,
  screenIndex,
}) => {
  const handleIntentClick = (intent: AvailabilityTypes) => {
    setAvailabilityEditingIntent(intent);
    setScreenIndex(screenIndex + 1);
  };

  return (
    <>
      <ModalHeader className="flex justify-center mb-5">
        <p className="text-body-bold font-body-bold text-neutral-900">
          What would you like to edit?
        </p>
      </ModalHeader>
      <ModalBody className="flex flex-col gap-4">
        <Button
          className="h-24 w-full rounded"
          variant="ghost"
          onClick={() =>
            handleIntentClick(AvailabilityTypes.WEEKLY_AVAILABILITY)
          }
        >
          <div className="flex flex-col gap-1">
            <p className="text-body-bold font-body-bold text-neutral-900">
              Repeating Shifts
            </p>
            <p className="text-zinc-500">All {availability?.day}s</p>
          </div>
        </Button>
        <Button
          className="h-24 w-full rounded"
          variant="ghost"
          onClick={() =>
            handleIntentClick(AvailabilityTypes.AVAILABILITY_OVERRIDE)
          }
        >
          <div className="flex flex-col gap-1">
            <p className="text-body-bold font-body-bold text-neutral-900">
              This day only
            </p>
            <p className="text-zinc-500">
              {format(parseISO(availability?.date), "MMM d, yyyy")}
            </p>
          </div>
        </Button>
      </ModalBody>
    </>
  );
};
