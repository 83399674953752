import { UserAuth, UserContextType } from "app/context/AuthContext";
import { Path } from "app/path";
import { OnboardingStep } from "app/redux/onboardingSlice";
import { userSignup } from "app/redux/userSlice";
import firebaseAuth from "app/utils/firebase";
import { getFormValues } from "app/utils/getFormValues";
import { parameterize } from "app/utils/string";
import { includes } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";

export const useSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { emailPassSignUp, init } = UserAuth() as UserContextType;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState<boolean>(false);

  const handleOnSignupSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setLoading(true);
      const { email, password, passwordConfirmation } = getFormValues(
        event.target
      );
      if (password !== passwordConfirmation) {
        setLoading(false);
        return toast.error("Password confirmation does not match password");
      }
      await emailPassSignUp(email, password);
      const user = firebaseAuth.currentUser;
      await dispatch(
        userSignup({ email: user!.email, accountType: "merchant" }) as any
      ).unwrap();
      await init(user);
      setLoading(false);
      navigate(
        generatePath(Path.ONBOARDING, {
          step: parameterize(OnboardingStep.BUSINESS_INFO),
        })
      );
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (
        error?.code === "auth/email-already-in-use" ||
        error?.data?.code === "account-exists"
      ) {
        return toast.error("This email already has an account");
      } else if (includes(error?.data?.error, "Email has already been taken")) {
        return toast.error("This email already has an account");
      } else if (error?.code === "auth/invalid-email") {
        return toast.error(
          "This email is invalid, please make sure you put in the right one"
        );
      } else {
        return toast.error("An error occurred, please try again later");
      }
    }
  };

  return {
    loading,
    handleOnSignupSubmit,
    showPassword,
    setShowPassword,
    showPasswordConfirmation,
    setShowPasswordConfirmation,
  };
};
