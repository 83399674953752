import { Path } from "app/path";
import { SalesSliceState, selectSales } from "app/redux/salesSlice";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SortDescriptor } from "react-stately";

export const useSalesTable = ({ rowsPerPage }: { rowsPerPage: number }) => {
  const query = useQuery();
  const pageParam = query.get("page");
  const navigate = useNavigate();
  const {
    sales = [], // Default to empty array if sales is undefined
    meta: { totalCount = 0 }, // Default to 0 if meta is undefined
  } = useSelector(selectSales);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(Number(pageParam) || 1);
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>(
    {}
  );

  const handlePageChange = (page: number) => {
    setPage(page);
    query.delete("page");
    query.set("page", page.toString());
    navigate(`${Path.SALES}?${query.toString()}`);
  };

  const sortedItems = React.useMemo(() => {
    // Avoid sorting if no items or sortDescriptor is empty
    if (sales.length === 0 || !sortDescriptor.column) return sales;

    return [...sales].sort((a: SalesSliceState, b: SalesSliceState) => {
      const first = a[sortDescriptor.column as keyof SalesSliceState] as number;
      const second = b[
        sortDescriptor.column as keyof SalesSliceState
      ] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, sales]);

  // Optionally handle loading state
  useEffect(() => {
    if (pageParam) {
      setPage(Number(pageParam));
    }
    setIsLoading(false); // Set to true when fetching data and false when done
  }, [sales, pageParam]);

  return {
    isLoading,
    setIsLoading,
    totalCount,
    page,
    handlePageChange,
    sortDescriptor,
    setSortDescriptor,
    sortedItems,
  };
};
