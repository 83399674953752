import * as React from "react";
import Darklogo from "app/assets/images/brand/darkLogo.svg";
import Brandlogo from "app/assets/images/brand/brandLogo.svg";
import BrandAllSet from "app/assets/images/brand/brandAllSet.svg";
import DarkAllSet from "app/assets/images/brand/darkAllSet.svg";

export interface LogoWithNameProps {
  type?: "light" | "dark";
  width?: string;
  height?: string;
  shouldScale?: boolean;
}

export const LogoWithName: React.FC<LogoWithNameProps> = ({
  type = "dark",
  width = "10",
  height = "6",
  shouldScale = true,
}) => {
  const logoTypes = {
    dark: {
      logo: Darklogo,
      allSet: DarkAllSet,
    },
    light: {
      logo: Brandlogo,
      allSet: BrandAllSet,
    },
  }[type];

  return (
    <div className="flex w-full items-center gap-2 justify-center">
      <img
        className={`h-${height} w-${width} flex-none object-cover ${
          shouldScale ? "scale-150" : ""
        }`}
        src={logoTypes.logo}
      />
      <img
        className={`h-${height} flex-none object-cover`}
        src={logoTypes.allSet}
      />
    </div>
  );
};
