import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserAuth, UserContextType } from "app/context/AuthContext";
import { useSelector } from "react-redux";
import { selectAccount } from "app/redux/accountSlice";
import { selectUser } from "app/redux/userSlice";

export enum SettingTypes {
  BUSINESS_DETAILS = "businessDetails",
  LOCATION = "location",
  BRANDING = "branding",
  BUSINESS_POLICIES = "businessPolicies",
  API_KEYS = "apiKeys",
}

export const useSettings = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const path = query.get("path");
  const account = useSelector(selectAccount);
  const user = useSelector(selectUser);
  const { logout } = UserAuth() as UserContextType;

  const onMenuPress = (key: string) => {
    navigate(`?path=${key}`);
  };

  useEffect(() => {
    if (!path) {
      navigate(`?path=${SettingTypes.BUSINESS_DETAILS}`);
    }
  }, [path]);

  return {
    onMenuPress,
    path,
    logout,
    account,
    user,
  };
};
