import { Icon } from "@subframe/core/dist/cjs/components/icon";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { AsButton, AsIconButton, DialogLayout } from "app/subframe";
import * as React from "react";
import { useState } from "react";

export interface DeleteSaleModalProps {
  isOpen: boolean;
  onOpenChange: any;
  onArchiveSale: any;
}

export const DeleteSaleModal: React.FC<DeleteSaleModalProps> = ({
  isOpen,
  onOpenChange,
  onArchiveSale,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleArchivingSale = async () => {
    setIsDeleting(true);
    await onArchiveSale();
    setIsDeleting(false);
    onOpenChange(false);
  };

  return (
    <FadeInSlide direction="bottom" className="absolute">
      <DialogLayout
        open={isOpen}
        onOpenChange={onOpenChange}
        className="z-[1000]"
      >
        <div className="flex max-w-112 flex-col items-center gap-4 rounded-lg bg-default-background px-6 py-6 shadow-dialog-shadow">
          <div className={"group/fe4ad82d flex w-full items-center gap-2"}>
            <Icon
              className="text-body font-body text-error-500"
              name="FeatherAlertTriangle"
            />
            <span
              className={
                "grow shrink-0 basis-0 text-[18px] font-[500] text-neutral-900"
              }
            >
              Before we delete
            </span>
            <AsIconButton
              className="h-8 w-8 flex-none"
              variant="ghost"
              leadingIcon="FeatherX"
              trailingIcon={null}
              text="Label"
              size="default"
              onClick={() => {
                onOpenChange(false);
              }}
            />
          </div>
          <div className="flex flex-col items-center gap-10 mt-2 mb-4 mobile:gap-5">
            <div className="flex flex-col items-center gap-2">
              <p>
                There are paid service in this sale. If you delete this sale,
                you will be voiding or refunding the services the customer has
                paid for.
              </p>
            </div>
          </div>
          <div className="flex items-center w-full">
            <AsButton
              variant="default"
              className="w-full !border-transparent !py-6 bg-red-600 hover:bg-red-500 active:bg-red-500 !text-white"
              text="Delete Sale"
              loader={isDeleting}
              onClick={() => handleArchivingSale()}
            />
          </div>
        </div>
      </DialogLayout>
    </FadeInSlide>
  );
};
