// Locations.tsx

import {
  Button,
  Listbox,
  ListboxItem,
  ListboxSection,
  Spinner,
  Tooltip,
} from "@nextui-org/react";
import { SectionHeader } from "app/components/sectionHeader";
import AddShopSvg from "app/assets/icons/addShop.svg";
import * as React from "react";
import { useLocations } from "./useLocations";
import classNames from "classnames";
import { Collapse } from "react-collapse";
import GrayTriangleSvg from "app/assets/icons/grayTriangle.svg";
import PhoneInput from "react-phone-input-2";
import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";
import { NewLocationModal } from "./components/newLocationModal";
import { AsBadge, AsInput } from "app/subframe";

interface LocationsProps {}

export const Locations: React.FC<LocationsProps> = () => {
  const {
    validShops,
    locationStates,
    onLocationInputChange,
    onLocationSelect,
    isCreatingNewShop,
    onSubmit,
    openShops,
    toggleShop,
    onShopArchiveToggle,
    isNewLocationModalOpen,
    setIsNewLocationModalOpen,
    setPhoneValue,
  } = useLocations();

  return (
    <>
      <NewLocationModal
        isOpen={isNewLocationModalOpen}
        onOpenChange={setIsNewLocationModalOpen}
      />
      <div className="flex relative flex-col w-full gap-4 ">
        <div className="flex items-center sticky top-0 bg-white z-[50] justify-between">
          <SectionHeader title="Location" />
          <Button
            variant="ghost"
            className={classNames("rounded-sm border border-black w-fit", {
              "opacity-50": isCreatingNewShop,
            })}
            onClick={() => setIsNewLocationModalOpen(true)}
            isDisabled={isCreatingNewShop}
          >
            {isCreatingNewShop ? (
              <Spinner size="md" color="primary" className="scale-75" />
            ) : (
              <img src={AddShopSvg} alt="Add shop" className="w-8 h-8 mb-2" />
            )}
            <p className="text-body-bold font-body-bold text-neutral-900">
              Add a new location
            </p>
          </Button>
        </div>
        {validShops.map((shop) => {
          const locationState = locationStates[shop.id];
          const isShopIncomplete =
            shop.name === "" ||
            shop.email === "" ||
            shop.phone === "" ||
            !shop.addressLine1;
          const shopIsArchived = !!shop.archivedAt;
          return (
            <div
              key={shop.id}
              className="flex p-2 flex-col rounded border border-[#E4E4E7]"
            >
              <button
                type="button"
                onClick={() => toggleShop(shop.id)}
                className="flex justify-between items-center w-full py-2"
              >
                <div className="flex items-center gap-2">
                  <p>{shop.name || "New Shop"}</p>
                  {isShopIncomplete && (
                    <AsBadge variant="warning" className="rounded">
                      Incomplete
                    </AsBadge>
                  )}
                  {shopIsArchived && (
                    <AsBadge variant="neutral" className="rounded">
                      Archived
                    </AsBadge>
                  )}
                </div>
                <div className="flex gap-2 items-center">
                  <Button
                    isIconOnly
                    variant="light"
                    className="rounded-sm"
                    onClick={() => onShopArchiveToggle(shop, shopIsArchived)}
                  >
                    {shopIsArchived ? (
                      <Tooltip content="Unarchive">
                        <ArrowPathIcon className="h-4 w-4 text-[#71717A]" />
                      </Tooltip>
                    ) : (
                      <Tooltip content="Archive">
                        <TrashIcon className="h-4 w-4 text-[#71717A]" />
                      </Tooltip>
                    )}
                  </Button>
                  <img
                    src={GrayTriangleSvg}
                    alt="Toggle"
                    className={classNames("h-3 w-3 mr-2 duration-300", {
                      "rotate-180": openShops[shop.id],
                    })}
                  />
                </div>
              </button>
              <Collapse isOpened={openShops[shop.id]}>
                <div className="py-4 gap-10 flex flex-col">
                  <form
                    className="flex flex-col gap-6"
                    onSubmit={(e) => onSubmit(shop.id, e)}
                  >
                    {/* Location Name */}
                    <div className="flex flex-col gap-2">
                      <label className="text-[#71717A] text-sm">
                        Location name
                      </label>
                      <AsInput
                        name="name"
                        placeholder="West End"
                        defaultValue={shop.name}
                        required
                        textPosition="left"
                      />
                    </div>

                    {/* Address Input with Suggestions */}
                    <div className="flex flex-col gap-2 relative">
                      <label className="text-[#71717A] text-sm">Address</label>
                      <AsInput
                        name="address"
                        placeholder="100 Main St, New York, NY 10001"
                        value={locationState.addressInputText}
                        onChange={(e) =>
                          onLocationInputChange(shop.id, e.target.value)
                        }
                        required
                        textPosition="left"
                      />
                      {locationState.locationSuggestions.length > 0 && (
                        <Listbox
                          className="absolute top-16 z-30 w-full"
                          classNames={{
                            base: "!bg-white rounded shadow-sm",
                          }}
                          onAction={(key: any) =>
                            onLocationSelect(shop.id, key)
                          }
                        >
                          <ListboxSection title="Suggestions" showDivider>
                            {locationState.locationSuggestions.map(
                              (location) => (
                                <ListboxItem
                                  classNames={{
                                    base: "hover:bg-[#E4E4E4] rounded-sm",
                                  }}
                                  key={location.name}
                                  textValue={location.name}
                                >
                                  {location.name}
                                </ListboxItem>
                              )
                            )}
                          </ListboxSection>
                        </Listbox>
                      )}
                    </div>

                    {/* Contact Email */}
                    <div className="flex flex-col gap-2">
                      <label className="text-zinc-500 text-sm">
                        Contact email
                      </label>
                      <AsInput
                        name="email"
                        type="email"
                        placeholder="you@company.com"
                        defaultValue={shop.email}
                        required
                        textPosition="left"
                      />
                    </div>

                    {/* Contact Phone */}
                    <div className="flex flex-col gap-2">
                      <label className="text-zinc-500 text-sm">
                        Contact phone
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={shop.phone}
                        specialLabel=""
                        onChange={(value) => setPhoneValue(shop.id, value)}
                        inputStyle={{
                          padding: "10px",
                          filter:
                            "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))",
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "1px solid #F4F4F5",
                          borderRadius: "4px",
                        }}
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                      />
                    </div>

                    {/* Tax Percentage */}
                    <div className="flex flex-col gap-2">
                      <label className="text-zinc-500 text-sm">
                        Tax Percentage (%)
                      </label>
                      <AsInput
                        name="taxPercentage"
                        type="number"
                        placeholder="0.0"
                        defaultValue={shop.taxPercentage}
                        required
                        textPosition="left"
                      />
                    </div>

                    <Button
                      variant="ghost"
                      className="rounded-sm border border-black w-fit px-6 self-end"
                      type="submit"
                      // isDisabled={
                      //   !locationState.isDirty ||
                      //   locationState.locationSuggestions.length > 0
                      // }
                    >
                      Update
                    </Button>
                  </form>
                </div>
              </Collapse>
            </div>
          );
        })}
      </div>
    </>
  );
};
