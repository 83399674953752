import { Button, Divider, Input } from "@nextui-org/react";
import { SectionHeader } from "app/components/sectionHeader";
import * as React from "react";
import { useSecurity } from "./useSecurity";

export interface SecurityProps {}

export const Security: React.FC<SecurityProps> = (props) => {
  const { onFormChange, allFieldsFilled, onSubmit } = useSecurity();

  return (
    <div className="flex flex-col w-full gap-6">
      <SectionHeader title="Security" />
      <form
        className="p-4 flex flex-col rounded border border-[#E4E4E7]"
        onChange={onFormChange}
        onSubmit={onSubmit}
      >
        <p className="text-body-bold font-body-bold text-neutral-900">
          {" "}
          Update password{" "}
        </p>
        <Divider className="my-4" />
        <div className="flex flex-col gap-10 mb-10">
          <Input
            label="Current password"
            labelPlacement="outside"
            classNames={{
              inputWrapper: "custom-input",
              label: "!text-zinc-500 text-sm",
            }}
            placeholder="Enter your current password"
            name="currentPassword"
          />
          <Input
            label="New password"
            labelPlacement="outside"
            classNames={{
              inputWrapper: "custom-input",
              label: "!text-zinc-500 text-sm",
            }}
            placeholder="Enter your new password"
            name="newPassword"
          />
          <Input
            label="Confirm password"
            labelPlacement="outside"
            classNames={{
              inputWrapper: "custom-input",
              label: "!text-zinc-500 text-sm",
            }}
            placeholder="Confirm your new password"
            name="confirmPassword"
          />
        </div>
        <Button
          className="w-[200px] rounded-sm self-end font-semibold border border-zinc-400"
          variant="ghost"
          isDisabled={!allFieldsFilled}
          type="submit"
        >
          Update
        </Button>
      </form>
    </div>
  );
};
