import { Button, Input, Link } from "@nextui-org/react";
import {
  AsButton,
  AsInput,
  PaymentInteractive,
  PaymentModalHeader,
} from "app/subframe";
import { useBreakpoint } from "app/utils/useBreakpoint";
import classNames from "classnames";
import { motion } from "framer-motion";
import { round } from "lodash";
import * as React from "react";

export interface CashCheckoutProps {
  initialTotalCents: number;
  recordPaidPayment: (event: any) => void;
  setPayWithMode: (mode: any) => void;
}

export const CashCheckout: React.FC<CashCheckoutProps> = ({
  recordPaidPayment,
  setPayWithMode,
  initialTotalCents,
}) => {
  const { isBelowSm } = useBreakpoint("sm");

  return (
    <form onSubmit={recordPaidPayment} className="flex flex-col gap-4 w-full">
      {isBelowSm ? (
        <>
          {isBelowSm && (
            <div className="fixed top-0 left-0 h-screen w-screen bg-[#171718]/80" />
          )}
          <motion.div
            className={classNames(
              "fixed bottom-0 left-0 flex h-96 flex-none flex-col items-center gap-6 rounded-t-large bg-white px-2 py-4 shadow-pop-up-shadow mobile:h-auto mobile:w-full"
            )}
            initial={{ y: 300, opacity: 0 }} // Start off-screen
            animate={{ y: 0, opacity: 1 }} // Slide up and fade in
            transition={{ duration: 0.4, ease: "easeInOut" }} // Animation settings
          >
            <PaymentModalHeader
              text="Record a Cash payment"
              buttonProps={{
                onClick: () => setPayWithMode(null),
              }}
            />
            <div className="flex items-center w-full justify-between">
              <p className="font-semibold">Total paid</p>
              <AsInput
                className="h-12 w-32 flex-none"
                leading="$"
                error={false}
                showLeadingIcon={false}
                disabled={false}
                type="number"
                defaultValue={
                  round(initialTotalCents / 100, 2).toFixed(2) as any
                }
                name="total"
              />
            </div>
            <AsButton
              className="h-12 w-full flex-none"
              variant="secondary"
              leadingIcon={null}
              trailingIcon={null}
              text="Record"
              size="md"
              loader={false}
              type="submit"
            />
          </motion.div>
        </>
      ) : (
        <>
          <PaymentModalHeader
            text="Record a Cash payment"
            size="small"
            buttonProps={{
              onClick: () => setPayWithMode(null),
            }}
          />
          <div className="flex items-center justify-between">
            <p className="font-semibold">Total paid</p>
            <AsInput
              className="h-12 w-32 flex-none"
              leading="$"
              error={false}
              showLeadingIcon={false}
              disabled={false}
              type="number"
              defaultValue={round(initialTotalCents / 100, 2).toFixed(2) as any}
              name="total"
            />
          </div>
          <AsButton
            className="h-10 w-32 flex-none self-end"
            variant="secondary"
            text="Record"
            size="default"
            loader={false}
            type="submit"
          />
        </>
      )}
    </form>
  );
};
