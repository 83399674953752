import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Spinner,
} from "@nextui-org/react";
import * as React from "react";
import { useAddCategoryModal } from "../addCategoryModal/useAddCategoryModal";
import { useAddServiceModal } from "./useAddServiceModal";
import { durationList } from "app/redux/businessServicesSlice";
import { find } from "lodash";

export interface AddServiceModalProps {
  showAddServiceModal: boolean;
  setShowAddServiceModal: (show: boolean) => void;
  serviceCategoryIdForNewService?: number;
}

export const AddServiceModal: React.FC<AddServiceModalProps> = ({
  showAddServiceModal,
  setShowAddServiceModal,
  serviceCategoryIdForNewService,
}) => {
  const { onFormChange, isValid, onSubmit, duration, setDuration, isCreating } =
    useAddServiceModal({
      setShowAddServiceModal,
      serviceCategoryIdForNewService,
    });
  return (
    <Modal
      isOpen={showAddServiceModal}
      onOpenChange={setShowAddServiceModal}
      size="lg"
      className="rounded"
      disableAnimation
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col items-center gap-10">
              Create new service category
            </ModalHeader>
            <form
              className="flex flex-col"
              onChange={(e) => onFormChange(e)}
              onSubmit={onSubmit}
            >
              <ModalBody className="py-4">
                <Input
                  classNames={{
                    label: "!text-[#71717A]",
                    inputWrapper:
                      "bg-transparent rounded shadow-sm border border-[#F4F4F5]",
                    input: "font-semibold placeholder:font-normal",
                  }}
                  labelPlacement="outside"
                  variant="bordered"
                  className="text-[#71717A]"
                  label="Service name"
                  name="title"
                  placeholder="Haircut"
                  isRequired
                />
                <div className="flex flex-col gap-4">
                  <div className="flex w-full justify-between items-center">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Duration
                    </p>
                    <Select
                      variant="bordered"
                      className="max-w-[120px] bg-transparent"
                      classNames={{
                        trigger: "border-[#F4F4F5] rounded shadow-sm border",
                      }}
                      name="duration"
                      value={duration?.duration}
                      onChange={(event) => {
                        setDuration({
                          duration: Number(event.target.value),
                          label: find(durationList, {
                            duration: Number(event.target.value),
                          }) as any,
                        });
                      }}
                    >
                      {durationList.map(({ duration, label }) => (
                        <SelectItem key={String(duration)} value={duration}>
                          {label}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Price
                    </p>
                    <Input
                      variant="bordered"
                      startContent="$"
                      type="number"
                      classNames={{
                        base: "w-full max-w-[120px]",
                        inputWrapper:
                          "border-[#F4F4F5] rounded shadow-sm border",
                        input: "font-semibold",
                      }}
                      name="price"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="w-[200px] rounded-sm self-end font-semibold border border-zinc-400"
                  variant="ghost"
                  isDisabled={!isValid}
                  type="submit"
                >
                  Create
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
