import { Path } from "app/path";
import { getClients, selectClients } from "app/redux/clientsSlice";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const perPageList = [30, 60, 90];
export const sortByList = ["name", "email"];

export const useClients = () => {
  const {
    clients,
    meta: { totalCount },
  } = useSelector(selectClients);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const pageParam = query.get("page");
  const clientIdParam = query.get("clientId");
  const [rowsPerPage, setRowsPerPage] = useState(perPageList[0]);
  const [sortBy, setSortBy] = useState(sortByList[0]);
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchClients = async () => {
    setLoading(true);
    try {
      await dispatch(
        getClients({
          perPage: rowsPerPage,
          sortBy,
          page: Number(pageParam) || 1,
        }) as any
      ).unwrap();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onClientRowClick = (id?: number) => {
    setShouldShowDrawer(true);
    query.delete("clientId");
    query.set("clientId", id!.toString());
    navigate(`${Path.CLIENTS}?${query.toString()}`);
  };

  const onOpenNewClientDrawer = () => {
    query.delete("clientId");
    navigate(`${Path.CLIENTS}?${query.toString()}`);
    if (!shouldShowDrawer) {
      setShouldShowDrawer(true);
    }
  };

  useEffect(() => {
    (async () => {
      fetchClients();
    })();
  }, [rowsPerPage, sortBy, pageParam]);

  useEffect(() => {
    if (clientIdParam) {
      setShouldShowDrawer(true);
    }
  }, []);

  return {
    clients,
    rowsPerPage,
    setRowsPerPage,
    perPageList,
    sortBy,
    setSortBy,
    shouldShowDrawer,
    setShouldShowDrawer,
    onClientRowClick,
    clientIdParam,
    onOpenNewClientDrawer,
    loading,
    totalCount,
  };
};
