import { Path } from "app/path";
import { selectEmployees } from "app/redux/employeesSlice";
import { archiveSale, selectSales, updateSale } from "app/redux/salesSlice";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useOpenSaleScreen = ({
  onCloseDrawer,
}: {
  onCloseDrawer: () => void;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const saleIdParam = query.get("saleId");
  const screenParam = query.get("screen");
  const { sales } = useSelector(selectSales);
  // const sale = find(sales, (sale: any) => sale.id === Number(saleIdParam));
  const [sale, setSale] = useState<any>();
  const employees = useSelector(selectEmployees);
  const employee = employees[0];
  const [isLoading, setIsLoading] = useState(false);

  const onArchiveSale = async () => {
    try {
      await dispatch(archiveSale(sale.id) as any).unwrap();
      query.delete("saleId");
      query.delete("screen");
      query.delete("status");
      toast.success(`Sale ${sale.id} archived successfully`);
      onCloseDrawer();
      navigate(Path.SALES, { replace: true });
    } catch (error) {
      console.log(error);
      toast.error(`Unable to archive sale ${sale.id}`);
    }
  };

  useEffect(() => {
    if (saleIdParam) {
      setIsLoading(true);
      const selectedSale = find(
        sales,
        (sale: any) => sale.id === Number(saleIdParam)
      ) as any;
      setSale(selectedSale);
      setIsLoading(false);
    }
  }, [sales, saleIdParam]);

  return {
    employee,
    isLoading,
    sale,
    screenParam,
    onArchiveSale,
  };
};
