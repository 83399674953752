import * as React from "react";
import { motion } from "framer-motion";
import { IconName } from "@subframe/core";
import { IconButton } from "app/subframe";
import classNames from "classnames";

interface CopyButtonProps {
  textToCopy: string;
  onCopy?: () => void; // Optional callback for additional actions after copy
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  onCopy,
}) => {
  const copyIcon = "FeatherCopy";
  const copiedIcon = "FeatherCheck";
  const [icon, setIcon] = React.useState<IconName>(copyIcon);

  const handleCopyClick = async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
      }
      setIcon(copiedIcon); // Change to copied icon
      if (onCopy) onCopy(); // Call the optional onCopy callback
      setTimeout(() => {
        setIcon(copyIcon); // Revert back to original icon after 1 second
      }, 1000);
    } catch (err) {
      console.error("Failed to copy the link: ", err);
    }
  };

  return (
    <motion.div
      className="flex items-center flex-none relative"
      onClick={handleCopyClick}
      transition={{
        duration: 0.3,
      }}
    >
      <span
        className={classNames(
          "absolute -translate-x-16 p-1 rounded z-50 bg-neutral-100 duration-100 text-bold text-sm font-semibold text-neutral-900",
          {
            "opacity-0": icon === copyIcon,
            "opacity-100": icon === copiedIcon,
          }
        )}
      >
        Copied
      </span>
      <IconButton
        variant="neutral-tertiary"
        size="medium"
        icon={icon}
        loading={false}
        onClick={handleCopyClick}
        className=""
      />
    </motion.div>
  );
};
