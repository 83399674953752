import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";
import { getSale } from "./salesSlice";

export enum PaymentMethod {
  CASH = "cash",
  CREDIT_CARD = "credit_card",
}

export interface PaymentTransactionSliceState {
  id: number;
  sale?: any;
  paymentLink?: any;
  referenceNumber?: number;
  paymentMethod?: PaymentMethod;
  total: string;
  totalCents: number;
  subtotalCents: number;
  subtotal: string;
  taxCents: number;
  tax: string;
  tipsCents: number;
  tips: string;
  createdAt: string;
  updatedAt: string;
}

// export const get = createAsyncThunk(
// );

export const createPaymentTransaction = createAsyncThunk(
  "paymentTransaction/createPaymentTransaction",
  async (paymentTransaction: any, thunkAPI) => {
    const { data } = await http.post(
      "/v1/payment_transactions",
      paymentTransaction
    );
    thunkAPI.dispatch(getSale(data.sale.id));
    return data;
  }
);

export const payWithCreditCard = createAsyncThunk(
  "paymentTransaction/payWithCreditCard",
  async (
    {
      source,
      amount,
      name,
      expiryMonth,
      expiryYear,
      tip,
      saleId,
      totalCents,
      subtotalCents,
      taxCents,
      tax,
      tipsCents,
      withExistingCard,
    }: any,
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(
        `/v1/payment_transactions/pay_with_credit_card`,
        {
          source,
          amount,
          name,
          expiryMonth,
          expiryYear,
          tip,
          saleId,
          totalCents,
          subtotalCents,
          taxCents,
          tax,
          tipsCents,
          withExistingCard,
        }
      );
      thunkAPI.dispatch(getSale(data.sale.id));
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const testApiKeys = createAsyncThunk(
  "sales/testApiKeys",
  async (
    {
      acceptBlueTokenizationApi,
      acceptBlueApiKey,
      acceptBlueApiPin,
    }: {
      acceptBlueTokenizationApi?: string;
      acceptBlueApiKey: string;
      acceptBlueApiPin: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/sales/test_charge`, {
        acceptBlueTokenizationApi,
        acceptBlueApiKey,
        acceptBlueApiPin,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const update = createAsyncThunk(
// );

// export const delete = createAsyncThunk(
// );
