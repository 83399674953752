// import { Button, SidebarRailWithIcons, Toast, AsButton } from "app/subframe";
import * as React from "react";

export interface PlaygroundProps {}

export const Playground: React.FC<PlaygroundProps> = ({}) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("onSubmit");
    console.log(e);
  };

  return (
    <div className="container max-w-none flex w-full flex-col items-center gap-4 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0"></div>
  );
};
