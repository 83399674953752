// @subframe/sync-disable

"use client";

import React, { useState } from "react";
import * as SubframeCore from "@subframe/core";
import { Collapse } from "react-collapse";
import classNames from "classnames";
import GrayTriangeSvg from "app/assets/icons/grayTriangle.svg";

interface CardAccordionRootProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  image?: string;
  expanded?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const CardAccordionRoot = React.forwardRef<HTMLElement, CardAccordionRootProps>(
  function CardAccordionRoot(
    {
      text,
      image = GrayTriangeSvg,
      expanded = false,
      className,
      children,
      ...otherProps
    }: CardAccordionRootProps,
    ref
  ) {
    // Local state to control expansion
    const [isExpanded, setIsExpanded] = useState(expanded);

    const toggleExpanded = () => setIsExpanded((prev) => !prev);

    return (
      <div
        className={SubframeCore.twClassNames(
          "group/dda3504c flex w-full flex-col items-center gap-4 rounded-lg bg-default-background px-4 pt-4  shadow-sm cursor-pointer",
          { "shadow-lg": isExpanded },
          className
        )}
        ref={ref as any}
        {...otherProps}
        onClick={toggleExpanded}
      >
        <div className="flex w-full items-center gap-2">
          {text ? (
            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
              {text}
            </span>
          ) : null}
          {image ? (
            <img
              className={classNames("flex-none", {
                "rotate-180": isExpanded,
                "rotate-90": !isExpanded,
              })}
              src={image}
              alt="accordion image"
            />
          ) : null}
        </div>
        <div
          className={classNames("w-full", {
            "pb-4": isExpanded,
          })}
        >
          <Collapse isOpened={isExpanded}>
            <div className="flex w-full flex-col items-center gap-2">
              {children}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
);

export const CardAccordion = CardAccordionRoot;
