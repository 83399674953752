import * as React from "react";
import { Listbox, ListboxItem } from "@nextui-org/react";
import { SettingTypes, useSettings } from "./useSettings";
import { BusinessDetails } from "./businessDetails/businessDetails";
import { Locations } from "./locations/locations";
import classNames from "classnames";
import { Branding } from "./branding/branding";
import { ApiKeys } from "./apiKeys/apiKeys";
import { BusinessPolicies } from "./businessPolicies/businessPolicies";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { MobileSetting } from "./mobileSetting/mobileSettings";

export interface SettingsProps {}

export const Settings: React.FC<SettingsProps> = (props) => {
  const { onMenuPress, path, logout, account, user } = useSettings();
  const { isBelowSm } = useBreakpoint("sm");

  const view = () => {
    switch (path) {
      case SettingTypes.BUSINESS_DETAILS:
        return <BusinessDetails />;
      case SettingTypes.LOCATION:
        return <Locations />;
      case SettingTypes.BRANDING:
        return <Branding />;
      case SettingTypes.BUSINESS_POLICIES:
        return <BusinessPolicies />;
      case SettingTypes.API_KEYS:
        return <ApiKeys />;
      default:
        return <div>Business Details</div>;
    }
  };

  if (isBelowSm) {
    return <MobileSetting onLogout={logout} account={account} user={user} />;
  }

  return (
    <div className="flex gap-6 my-10 max-w-[750px] mx-auto">
      <Listbox
        className="max-w-[200px] p-4 rounded shadow h-fit"
        aria-label="Actions"
        onAction={(key: any) => onMenuPress(key)}
      >
        <ListboxItem
          classNames={{
            base: `${
              path === SettingTypes.BUSINESS_DETAILS ? "bg-[#E4E4E4]" : ""
            } rounded-sm`,
          }}
          key={SettingTypes.BUSINESS_DETAILS}
        >
          <p
            className={classNames(
              path === SettingTypes.BUSINESS_DETAILS ? "font-semibold" : ""
            )}
          >
            Business Details
          </p>
        </ListboxItem>
        <ListboxItem
          classNames={{
            base: `${
              path === SettingTypes.LOCATION ? "bg-[#E4E4E4]" : ""
            } rounded-sm`,
          }}
          key={SettingTypes.LOCATION}
        >
          <p
            className={classNames(
              path === SettingTypes.LOCATION ? "font-semibold" : ""
            )}
          >
            Location
          </p>
        </ListboxItem>
        <ListboxItem
          key={SettingTypes.BRANDING}
          classNames={{
            base: `${
              path === SettingTypes.BRANDING ? "bg-[#E4E4E4]" : ""
            } rounded-sm`,
          }}
        >
          <p
            className={classNames(
              path === SettingTypes.BRANDING ? "font-semibold" : ""
            )}
          >
            Branding
          </p>
        </ListboxItem>
        <ListboxItem
          key={SettingTypes.BUSINESS_POLICIES}
          classNames={{
            base: `${
              path === SettingTypes.BUSINESS_POLICIES ? "bg-[#E4E4E4]" : ""
            } rounded-sm`,
          }}
        >
          <p
            className={classNames(
              path === SettingTypes.BUSINESS_POLICIES ? "font-semibold" : ""
            )}
          >
            Business Policies
          </p>
        </ListboxItem>
        <ListboxItem
          key={SettingTypes.API_KEYS}
          classNames={{
            base: `${
              path === SettingTypes.API_KEYS ? "bg-[#E4E4E4]" : ""
            } rounded-sm`,
          }}
        >
          <p
            className={classNames(
              path === SettingTypes.API_KEYS ? "font-semibold" : ""
            )}
          >
            API Keys
          </p>
        </ListboxItem>
      </Listbox>
      <div className="h-[90vh] overflow-auto w-full">{view()}</div>
    </div>
  );
};
