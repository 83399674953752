"use client";
/*
 * Documentation:
 * Menu item — https://app.subframe.com/feb895689ba0/library?component=Menu+item_da2b41c7-93bc-404f-ad82-8e0bc45a0f6a
 * Badge — https://app.subframe.com/feb895689ba0/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Badge } from "./Badge";

interface MenuItemRootProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: SubframeCore.IconName;
  text?: string;
  className?: string;
}

const MenuItemRoot = React.forwardRef<HTMLElement, MenuItemRootProps>(
  function MenuItemRoot(
    {
      icon = "FeatherSettings",
      text,
      className,
      ...otherProps
    }: MenuItemRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex items-center gap-2",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex grow shrink-0 basis-0 items-center gap-2">
          <div className="flex h-10 w-10 flex-none items-center justify-center gap-2">
            <SubframeCore.Icon
              className="text-[20px] font-[400] leading-[24px] text-neutral-500"
              name={icon}
            />
          </div>
          {text ? (
            <span className="text-body font-body text-neutral-500">{text}</span>
          ) : null}
        </div>
        <Badge variant="neutral" icon={null} iconRight={null}>
          On desktop
        </Badge>
      </div>
    );
  }
);

export const MenuItem = MenuItemRoot;
