import { selectEmployees } from "app/redux/employeesSlice";
import { selectShops } from "app/redux/shopsSlice";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useHeader = ({}: any) => {
  const query = useQuery();
  const navigate = useNavigate();
  const employees = useSelector(selectEmployees);
  const shops = useSelector(selectShops);
  const shop = find(shops, { id: Number(query.get("shopId")) });
  const [isEmployeeSelectOpen, setIsEmployeeSelectOpen] = React.useState(false);
  const [isShopSelectOpen, setIsShopSelectOpen] = React.useState(false);

  const onShopChange = ({ label, value }: any) => {
    query.set("shopId", value.toString());
    navigate(`?${query.toString()}`);
  };

  return {
    shop,
    shops,
    onShopChange,
    employees,
    isEmployeeSelectOpen,
    setIsEmployeeSelectOpen,
    isShopSelectOpen,
    setIsShopSelectOpen,
  };
};
