import * as React from "react";
import { perPageList, sortByList, useClients } from "./useClients";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import {
  PlusIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import { ClientTable } from "./components/clientTable/clientTable";
import classNames from "classnames";
import { titleize } from "app/utils/string";
import { Drawer } from "app/components/drawer/drawer";
import { AddClient } from "./components/drawer/addClient/addClient";
import { UpdateClient } from "./components/drawer/updateClient/updateClient";
import { Loading } from "app/components/loading/loading";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { AsButton } from "app/subframe";

export interface ClientsProps {}

export const Clients: React.FC<ClientsProps> = ({}) => {
  const {
    clients,
    rowsPerPage,
    setRowsPerPage,
    sortBy,
    setSortBy,
    shouldShowDrawer,
    setShouldShowDrawer,
    onClientRowClick,
    clientIdParam,
    onOpenNewClientDrawer,
    loading,
    totalCount,
  } = useClients();

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  return (
    <div
      className={classNames("flex flex-col p-4 gap-4 mobile:p-2", {
        "h-[85dvh]": isBelowSm && shouldShowDrawer,
      })}
    >
      {isBelowSm && (
        <span className="text-[20px] font-[500] leading-[30px] text-neutral-700">
          Clients
        </span>
      )}
      <Drawer
        isOpen={shouldShowDrawer}
        setIsOpen={setShouldShowDrawer}
        backdrop={false}
      >
        {clientIdParam ? (
          <UpdateClient clientId={Number(clientIdParam)} />
        ) : (
          <AddClient setCloseParent={setShouldShowDrawer} key={Date.now()} />
        )}
      </Drawer>
      <div className="flex items-center justify-between">
        {isAboveSm ? (
          <div className="flex gap-2 items-center">
            <AsButton
              variant="neutral-secondary"
              size="md"
              onClick={() => onOpenNewClientDrawer()}
            >
              <PlusIcon className="w-4 h-4 stroke-black" />
              <p className="font-semibold ">Add Client</p>
            </AsButton>
            <p className="text-zinc-500">{clients.length} clients</p>
          </div>
        ) : (
          <span className="grow shrink-0 basis-0 text-[14px] font-[400] leading-[20px] text-neutral-700">
            {totalCount} clients
          </span>
        )}
        <div className="flex gap-4">
          <Dropdown>
            <DropdownTrigger>
              <AsButton
                variant="ghost"
                trailingIcon="FeatherChevronDown"
                text="Sort"
                size="sm"
                loader={false}
                disabled={false}
                disabledSecondary={false}
              />
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              {sortByList.map((sb) => (
                <DropdownItem
                  key={sb}
                  onClick={() => setSortBy(sb)}
                  endContent={
                    <CheckIcon
                      className={classNames("w-3 h-3 stroke-black", {
                        hidden: sb !== sortBy,
                      })}
                    />
                  }
                >
                  {titleize(sb)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Dropdown>
            <DropdownTrigger>
              <AsButton
                variant="ghost"
                leadingIcon={null}
                trailingIcon="FeatherChevronDown"
                className="text-neutral-900"
                text={`${rowsPerPage} per page`}
                size="sm"
                loader={false}
              />
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              {perPageList.map((perPage) => (
                <DropdownItem
                  key={perPage}
                  onClick={() => setRowsPerPage(perPage)}
                >
                  {perPage} per page
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <ClientTable
        rowsPerPage={rowsPerPage}
        onClientRowClick={onClientRowClick}
      />
      {isBelowSm && (
        <AsButton
          variant="brand"
          leadingIcon="FeatherPlus"
          trailingIcon={null}
          text="Add client"
          size="md"
          loader={false}
          shadow
          onClick={() => onOpenNewClientDrawer()}
          className="fixed bottom-24 self-center z-50"
        />
      )}
    </div>
  );
};
