import { updateAccount } from "app/redux/accountSlice";
import { OnboardingStep, updateOnboarding } from "app/redux/onboardingSlice";
import { getFormValues } from "app/utils/getFormValues";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import firebaseAuth from "app/utils/firebase";
import { UserAuth, UserContextType } from "app/context/AuthContext";

export const usePaymentApiKeys = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: string) => void;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { init } = UserAuth() as UserContextType;

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const { apiKey, apiPin, apiTokenizationKey } = getFormValues(event.target);
    const apiKeyPayload = {
      acceptBlueTokenizationApi: apiTokenizationKey,
      acceptBlueApiKey: apiKey,
      acceptBlueApiPin: apiPin,
    };
    try {
      setLoading(true);

      // await dispatch(testApiKeys(apiKeyPayload) as any).unwrap();
      await dispatch(updateAccount(apiKeyPayload) as any).unwrap();

      await dispatch(
        updateOnboarding({
          step: OnboardingStep.COMPLETE,
        }) as any
      ).unwrap();
      const user = firebaseAuth.currentUser;
      await init(user);
      setCurrentStep(OnboardingStep.COMPLETE);
      setLoading(false);
      toast.success("You're all set!");
    } catch (error: any) {
      if (error.data.error === "Unauthorized") {
        toast.error("Invalid API keys");
      }
      setLoading(false);
      console.error(error);
    }
  };

  return {
    onSubmit,
    loading,
  };
};
