"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Line items — https://app.subframe.com/feb895689ba0/library?component=Line+items_2d4f186c-0e37-4188-91cc-49095b2e098e
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { SkeletonText } from "./SkeletonText";

interface LineItemsRootProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  price?: string;
  additionalInfo?: string;
  className?: string;
  isLoading?: boolean;
}

const LineItemsRoot = React.forwardRef<HTMLElement, LineItemsRootProps>(
  function LineItemsRoot(
    {
      title,
      price,
      additionalInfo,
      className,
      isLoading = false,
      ...otherProps
    }: LineItemsRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-full items-center gap-4",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex grow shrink-0 basis-0 items-center gap-1">
          {title ? (
            <span className="text-body font-body text-neutral-700">
              {title}
            </span>
          ) : null}
          {additionalInfo ? (
            <span className="text-body font-body text-neutral-700">
              {additionalInfo}
            </span>
          ) : null}
        </div>
        {price && !isLoading ? (
          <span className="text-body-bold font-body-bold text-neutral-900">
            {price}
          </span>
        ) : (
          <SkeletonText className="w-24" />
        )}
      </div>
    );
  }
);

export const LineItems = LineItemsRoot;
