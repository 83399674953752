import { sendPaymentLinkWithEmail } from "app/redux/paymentLinkSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const usePaymentLinkCcCheckout = ({
  paymentLink,
  sale,
}: {
  paymentLink: any;
  sale: any;
}) => {
  const dispatch = useDispatch();
  const [showCopiedText, setShowCopiedText] = useState<boolean>(false);
  const [emailToSendPaymentLink, setEmailToSendPaymentLink] = useState<string>(
    sale.client.email
  );

  const copyPaymentLink = async () => {
    await navigator.clipboard.writeText(paymentLink.link);
    setShowCopiedText(true);
    setTimeout(() => {
      setShowCopiedText(false);
    }, 1000);
  };

  const onSendPaymentLinkWithEmail = async () => {
    try {
      await dispatch(
        sendPaymentLinkWithEmail({
          id: paymentLink.id,
          email: emailToSendPaymentLink,
        }) as any
      ).unwrap();
      toast.success("Payment link sent successfully");
    } catch (err) {
      console.log(err);
      toast.error("Failed to send payment link, perhaps try another email");
    }
  };

  return {
    copyPaymentLink,
    showCopiedText,
    emailToSendPaymentLink,
    setEmailToSendPaymentLink,
    onSendPaymentLinkWithEmail,
  };
};
