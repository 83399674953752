"use client";

// @subframe/sync-disable

import React from "react";
import * as SubframeCore from "@subframe/core";
import InactiveScheduleIcon from "app/assets/icons/mobileNavbar/inactiveSchedule.svg";
import ActiveScheduleIcon from "app/assets/icons/mobileNavbar/activeSchedule.svg";
import InactiveSalesIcon from "app/assets/icons/mobileNavbar/inactiveSales.svg";
import ActiveSalesIcon from "app/assets/icons/mobileNavbar/activeSales.svg";
import InactiveClientsIcon from "app/assets/icons/mobileNavbar/inactiveClients.svg";
import ActiveClientsIcon from "app/assets/icons/mobileNavbar/activeClients.svg";
import InactiveSettingsIcon from "app/assets/icons/mobileNavbar/inactiveHome.svg";
import ActiveSettingsIcon from "app/assets/icons/mobileNavbar/activeHome.svg";
import InactiveMenuIcon from "app/assets/icons/mobileNavbar/inactiveMenu.svg";
import ActiveMenuIcon from "app/assets/icons/mobileNavbar/activeMenu.svg";

import { Path } from "app/path";
import { Link } from "react-router-dom";

interface MobileNavBarRootProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const MobileNavBarRoot = React.forwardRef<HTMLElement, MobileNavBarRootProps>(
  function MobileNavBarRoot(
    { className, ...otherProps }: MobileNavBarRootProps,
    ref
  ) {
    const navLinks = [
      {
        name: "Schedule",
        inactiveImage: InactiveScheduleIcon,
        activeImage: ActiveScheduleIcon,
        to: Path.HOME,
      },
      {
        name: "Sales",
        inactiveImage: InactiveSalesIcon,
        activeImage: ActiveSalesIcon,
        to: Path.SALES,
      },
      {
        name: "Clients",
        inactiveImage: InactiveClientsIcon,
        activeImage: ActiveClientsIcon,
        to: Path.CLIENTS,
      },
      {
        name: "Menu",
        inactiveImage: InactiveMenuIcon,
        activeImage: ActiveMenuIcon,
        to: Path.SETTINGS,
      },
    ];

    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-full items-center justify-between rounded-t-2xl bg-[#f5f3efb3] !h-20 px-4 pb-2 shadow-pop-up-shadow backdrop-blur-sm",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {navLinks.map((navLink, index) => {
          const isSelected = window.location.pathname === navLink.to;

          return (
            <Link
              key={index}
              to={navLink.to}
              className="group flex w-12 flex-none flex-col items-center justify-center "
            >
              <div className="flex w-full flex-col items-center justify-center gap-3 pb-2">
                <div
                  className={`flex h-1 w-full flex-none flex-col items-center gap-2 rounded-[1px] ${
                    isSelected ? "bg-[#444449ff]" : "bg-transparent"
                  }`}
                />
                <img
                  // src={isSelected ? navLink.activeImage : navLink.inactiveImage}
                  src={navLink.inactiveImage}
                  className="h-8 w-8 flex-none object-cover"
                  alt={`${navLink.name} Icon`}
                />
              </div>
              <span
                className={`font-['Inter'] text-[12px] font-[500] leading-[16px] ${
                  isSelected ? "text-neutral-700" : "text-neutral-500"
                }`}
              >
                {navLink.name}
              </span>
            </Link>
          );
        })}
      </div>
    );
  }
);

export const MobileNavBar = MobileNavBarRoot;
