import * as React from "react";
import { usePaymentApiKeys } from "./usePaymentApiKeys";
import { AsButton } from "app/subframe";
import { BrandInput } from "app/subframe/components/BrandInput";
import { OnboardingStep } from "app/redux/onboardingSlice";
import { LogoWithName } from "app/components/logoWithName";

export interface PaymentApiKeysProps {
  step: number;
  totalSteps: number;
  setCurrentStep: (step: string) => void;
}

export const PaymentApiKeys: React.FC<PaymentApiKeysProps> = ({
  step,
  totalSteps,
  setCurrentStep,
}) => {
  const { loading, onSubmit } = usePaymentApiKeys({ setCurrentStep });

  return (
    <div className="flex h-full w-full flex-col items-center gap-16 bg-millet-500 px-10 py-10 pt-20 mobile:flex-col mobile:gap-14 mobile:px-4 mobile:pt-10 mobile:pb-20">
      <div className="absolute top-5 left-5">
        <LogoWithName width="4" height="4" />
      </div>
      <div className="flex flex-col items-center gap-14 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0">
        <div className="flex flex-col items-center justify-center gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
          <span className="text-[14px] font-[400] leading-[20px] text-neutral-700">
            Step {step} of {totalSteps}
          </span>
          <span className="text-heading-2 font-heading-2 text-neutral-700 text-center mobile:h-auto mobile:w-full mobile:flex-none">
            Please enter your gateway payment API keys.
          </span>
        </div>
        <form
          onSubmit={onSubmit}
          className="flex flex-col items-center gap-12 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:items-center mobile:justify-start"
        >
          <div className="flex flex-col items-center gap-10 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0">
            <div className="flex w-96 flex-col items-start gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
              <span className="text-body-bold font-body-bold text-neutral-700">
                API Key *
              </span>
              <BrandInput
                className="h-14 w-full flex-none"
                placeholder="Your API key *"
                textPosition="left"
                name="apiKey"
                required
              />
            </div>
            <div className="flex w-96 flex-col items-start gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
              <span className="text-body-bold font-body-bold text-neutral-700">
                API Pin *
              </span>
              <BrandInput
                className="h-14 w-full flex-none"
                placeholder="Your API pin *"
                textPosition="left"
                name="apiPin"
                required
              />
            </div>
            <div className="flex w-96 flex-col items-start gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
              <span className="text-body-bold font-body-bold text-neutral-700">
                Tokenization Key *
              </span>
              <BrandInput
                className="h-14 w-full flex-none"
                placeholder="Your tokenization key *"
                textPosition="left"
                name="apiTokenizationKey"
                required
              />
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-10">
            <AsButton
              className="h-12 w-full flex-none"
              variant="brand"
              text="I am all set"
              size="md"
              loader={loading}
              type="submit"
            />
            <AsButton
              variant="link"
              text="Back"
              size="xs"
              loader={false}
              onClick={() =>
                setCurrentStep(OnboardingStep.HOW_MANY_SERVICE_PROVIDERS)
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
