import * as React from "react";
import { useOpenSaleScreen } from "./useOpenSaleScreen";
import { NewCheckout } from "./components/newCheckout/newCheckout";
import { PaymentCheckout } from "./components/paymentCheckout/paymentCheckout";
import {
  CheckoutScreenTypes,
  SaleStatusConditionTypes,
} from "app/sales/useSales";

export interface OpenSaleScreenProps {
  saleStatusCondition: SaleStatusConditionTypes | null;
  handleClientSearch: (inputValue: string, callback: any) => void;
  onCloseDrawer: () => void;
}

export const OpenSaleScreen: React.FC<OpenSaleScreenProps> = ({
  saleStatusCondition,
  handleClientSearch,
  onCloseDrawer,
}) => {
  const { sale, screenParam, onArchiveSale } = useOpenSaleScreen({
    onCloseDrawer,
  });

  return screenParam === CheckoutScreenTypes.PAYMENT ? (
    <PaymentCheckout
      saleId={sale?.id}
      saleStatusCondition={saleStatusCondition}
      onCloseDrawer={onCloseDrawer}
      onArchiveSale={onArchiveSale}
    />
  ) : (
    <NewCheckout
      handleClientSearch={handleClientSearch}
      saleStatusCondition={saleStatusCondition}
      onCloseDrawer={onCloseDrawer}
      onArchiveSale={onArchiveSale}
    />
  );
};
