"use client";
/*
 * Documentation:
 * Onboarding card — https://app.subframe.com/feb895689ba0/library?component=Onboarding+card_8cc7e93e-159b-468c-8011-9631890358f4
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface OnboardingCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: string;
  number?: string;
  className?: string;
  selected?: boolean;
  image?: any;
}

const OnboardingCardRoot = React.forwardRef<
  HTMLElement,
  OnboardingCardRootProps
>(function OnboardingCardRoot(
  {
    size,
    number,
    className,
    selected,
    image,
    ...otherProps
  }: OnboardingCardRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/8cc7e93e flex h-52 w-52 cursor-pointer flex-col items-center justify-end gap-1 rounded-lg border border-solid border-neutral-300 bg-millet-100- px-5 py-5 hover:border hover:border-solid hover:border-neutral-700 hover:bg-millet-300 active:border active:border-solid active:border-neutral-900 active:bg-millet-550 focus-within:border focus-within:border-solid focus-within:border-neutral-900 focus-within:bg-millet-550",
        selected
          ? "border border-solid !border-neutral-900 !bg-millet-550"
          : "",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      {image ? image : null}
      {size ? (
        <span className="text-body-bold font-body-bold text-neutral-700 text-center">
          {size}
        </span>
      ) : null}
      {number ? (
        <span className="text-body-bold font-body-bold text-neutral-700 text-center">
          {number}
        </span>
      ) : null}
    </div>
  );
});

export const OnboardingCard = OnboardingCardRoot;
