import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface ShopsSliceState {
  id: number;
  name: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  timezone: any;
  taxPercentage: number;
  latitude: number;
  longitude: number;
  availability: any;
  createdAt: string;
  updatedAt: string;
}

const initialState = [] as ShopsSliceState[];

export const getShops = createAsyncThunk(
  "shops/getShops",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/v1/shops");
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createShop = createAsyncThunk(
  "shops/createShop",
  async (shop: any, thunkAPI) => {
    try {
      const { data } = await http.post("/v1/shops", shop);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateShop = createAsyncThunk(
  "shops/updateShop",
  async (shop: any, thunkAPI) => {
    try {
      const { data } = await http.put(`/v1/shops/${shop.id}`, shop);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const deleteShop = createAsyncThunk(
// );

export const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShops.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(createShop.fulfilled, (state, action) => {
      state.push(action.payload);
    });
    builder.addCase(updateShop.fulfilled, (state, action) => {
      const shopIndex = state.findIndex(
        (shop) => shop.id === action.payload.id
      );
      state[shopIndex] = action.payload;
    });
  },
});

export const selectShops = (state: RootState) => state.shops;
