"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Sales card — https://app.subframe.com/feb895689ba0/library?component=Sales+card_cfce4734-cb30-460e-953e-e40a474960a6
 * Avatar — https://app.subframe.com/feb895689ba0/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 * AsBadge — https://app.subframe.com/feb895689ba0/library?component=AsBadge_a23f3ea7-3649-4c1b-9f5a-e2094098f065
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Avatar as SFAvatar } from "./Avatar";
import { AsBadge } from "./AsBadge";
import { Avatar } from "app/components/avatar/avatar";

interface SalesCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  client?: string;
  date?: string;
  price?: string;
  status?: "default" | "closed" | "void";
  pressed?: boolean;
  className?: string;
  avatarImg?: string;
}

const SalesCardRoot = React.forwardRef<HTMLElement, SalesCardRootProps>(
  function SalesCardRoot(
    {
      client,
      date,
      price,
      status = "default",
      pressed = false,
      className,
      avatarImg,
      ...otherProps
    }: SalesCardRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/cfce4734 flex w-full items-center gap-2 rounded-lg border border-solid border-neutral-50 bg-white px-2 py-4 active:border active:border-solid active:border-neutral-500 active:bg-millet-300",
          { "border border-solid border-neutral-500 bg-millet-300": pressed },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <Avatar size={56} src={avatarImg!} name={client!} type="character" />
        <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-2">
          <div className="flex w-full items-center gap-2">
            {client ? (
              <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
                {client}
              </span>
            ) : null}
            <AsBadge
              className={SubframeCore.twClassNames({
                hidden: status === "void" || status === "closed",
              })}
              variant="warning"
              icon={null}
              iconRight={null}
            >
              Open
            </AsBadge>
            <AsBadge
              className={SubframeCore.twClassNames("hidden", {
                flex: status === "closed",
              })}
              variant="neutral"
              icon={null}
              iconRight={null}
            >
              Closed
            </AsBadge>
            <AsBadge
              className={SubframeCore.twClassNames("hidden", {
                flex: status === "void",
              })}
              variant="void"
              icon={null}
              iconRight={null}
            >
              Void
            </AsBadge>
          </div>
          <div className="flex w-full items-center gap-2">
            {date ? (
              <span className="grow shrink-0 basis-0 text-[14px] font-[400] leading-[20px] text-neutral-500">
                {date}
              </span>
            ) : null}
            {price ? (
              <span className="text-[16px] font-[600] leading-[24px] text-neutral-900">
                {price}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);

export const SalesCard = SalesCardRoot;
