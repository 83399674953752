import { selectAccount, updateAccount } from "app/redux/accountSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useBusinessPolicies = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);

  const onUpdateAccount = (keyValue: any) => {
    try {
      dispatch(updateAccount(keyValue) as any).unwrap();
      toast.success("Business policies updated successfully");
    } catch (error: any) {
      toast.error("Failed to update business policies");
      console.log(error);
    }
  };

  return {
    account,
    onUpdateAccount,
  };
};
