import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithCredential,
  updateEmail,
  verifyBeforeUpdateEmail,
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  getIdToken,
  updatePassword,
} from "firebase/auth";
import firebaseAuth from "app/utils/firebase";
import { useDispatch } from "react-redux";
import { getUser, userSignup } from "app/redux/userSlice";
import { useHistoryTracker } from "./hooks/usehistoryTracker";
import { useQuery } from "app/utils/useQuery";
import http from "app/requests/axiosInstance";
import { toast } from "react-toastify";
import { Loading } from "app/components/loading/loading";
import { getAccount } from "app/redux/accountSlice";
import { getShops } from "app/redux/shopsSlice";
import { getEmployees } from "app/redux/employeesSlice";
import { getBusinessServices } from "app/redux/businessServicesSlice";
import { getClients } from "app/redux/clientsSlice";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Path } from "app/path";
import { getOnboarding } from "app/redux/onboardingSlice";
import { getSales } from "app/redux/salesSlice";

export interface UserContextType {
  emailPassSignUp: (email: string, password: string) => Promise<any>;
  user: any;
  logout: () => Promise<any>;
  emailPassSignIn: (email: string, password: string) => Promise<any>;
  googlePopupSignIn: () => Promise<any>;
  googleRedirectSignIn: () => Promise<any>;
  signInWithCustomToken: (token: string) => Promise<any>;
  handleGoogleRedirect: () => Promise<any>;
  handleGooglePopupSignin: () => Promise<any>;
  updateUserEmail: (email: string) => Promise<any>;
  reauthenticateUser: (password: string) => Promise<any>;
  updateUserPassword: (password: string) => Promise<any>;
  history: any[];
  init: (currentUser: any) => Promise<any>;
}

export const UserContext: any = createContext({});

export const AuthContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const history = useHistoryTracker(); // Use the custom history tracking hook
  const query = useQuery();

  const googlePopupSignIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    return signInWithPopup(firebaseAuth, provider);
  };

  const googleRedirectSignIn = ({ redirectUri }: { redirectUri: string }) => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const responseType = "code";
    const scope = "email profile";
    const state = "google";

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&prompt=select_account&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${encodeURIComponent(
      state
    )}`;
    return (window.location.href = authUrl);
  };

  const emailPassSignUp = async (email: string, password: string) => {
    return await createUserWithEmailAndPassword(firebaseAuth, email, password);
  };

  const emailPassSignIn = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(firebaseAuth, email, password);
  };

  const signInWithCustomToken = async (token: string) => {
    return await firebaseSignInWithCustomToken(firebaseAuth, token);
  };

  const logout = async () => {
    setUser(null);
    return signOut(firebaseAuth);
  };

  const reauthenticateUser = async (password: string) => {
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(
      auth.currentUser!.email as any,
      password
    );

    try {
      await reauthenticateWithCredential(auth.currentUser as any, credential);
    } catch (error: any) {
      throw error;
    }
  };

  const updateUserEmail = async (email: string) => {
    const auth = getAuth();
    if (auth.currentUser) {
      try {
        await updateEmail(auth.currentUser, email);
        setUser({ ...user, email });
      } catch (error: any) {
        throw error;
      }
    }
  };

  const updateUserPassword = async (password: string) => {
    const auth = getAuth();
    if (auth.currentUser) {
      try {
        await updatePassword(auth.currentUser, password);
      } catch (error: any) {
        throw error;
      }
    }
  };

  const completeGoogleSignIn = async (googleUser: any) => {
    const nameArray = googleUser.displayName.split(" ");
    try {
      const user = await dispatch(
        userSignup({
          email: googleUser.email,
          firstName: nameArray[0],
          lastName: nameArray[1],
          // uid: googleUser.uid,
        }) as any
      ).unwrap();
      setUser(user);
    } catch (error: any) {
      toast.error(error.code || error.message);
    }
  };

  const handleGooglePopupSignin = async () => {
    try {
      const { user } = await googlePopupSignIn();
      await completeGoogleSignIn(user);
    } catch (error: any) {
      toast.error(error.code || error.message);
    }
  };

  const handleGoogleRedirect = async () => {
    const { data } = await http.post("https://oauth2.googleapis.com/token", {
      code: query.get("code"),
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: `${window.location.origin}${window.location.pathname}`,
      grant_type: "authorization_code",
    });

    const googleCredential = GoogleAuthProvider.credential(data.idToken); // idToken received from the server
    const { user } = await signInWithCredential(firebaseAuth, googleCredential);
    await completeGoogleSignIn(user);
  };

  const init = async (currentUser: any) => {
    setLoading(true);
    try {
      if (currentUser) {
        const userWithInfo = await dispatch(getUser() as any).unwrap();
        setUser(currentUser);
        setUser(userWithInfo);
        await dispatch(getAccount() as any).unwrap();
        await dispatch(getOnboarding() as any).unwrap();
        await dispatch(getBusinessServices() as any).unwrap();
        await dispatch(getEmployees() as any).unwrap();
        await dispatch(getShops() as any).unwrap();
        await dispatch(getClients({}) as any).unwrap();
        if ([Path.LOGIN, Path.SIGNUP].includes(location.pathname as any)) {
          navigate(Path.HOME);
        }
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
      (async () => {
        if (currentUser) {
          await init(currentUser);
        }
        setLoading(false);
      })();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="absolute left-1/2 self-center top-1/3 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  return (
    <UserContext.Provider
      value={{
        emailPassSignUp,
        user,
        logout,
        emailPassSignIn,
        googlePopupSignIn,
        googleRedirectSignIn,
        signInWithCustomToken,
        handleGoogleRedirect,
        handleGooglePopupSignin,
        updateUserEmail,
        reauthenticateUser,
        updateUserPassword,
        history,
        init,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
