import { Drawer } from "app/components/drawer/drawer";
import { CrudAppointmentActions } from "app/schedules/useSchedules";
import * as React from "react";
import { ReadAppointmentScreen } from "app/schedules/components/crudAppointmentScreens/readAppointmentScreen/readAppointmentScreen";
import { CreateAppointmentScreen } from "app/schedules/components/crudAppointmentScreens/createAppointmentScreen/createAppointmentScreen";

export interface ScheduleDrawerProps {
  appointmentDrawer: any;
  toggleAppointmentDrawer: any;
  setAppointmentDrawer: any;
  selectedAppointmentEvent: any;
  getSchedulesOfEmployees: any;
  setPendingAppointment: any;
  pendingAppointment: any;
}

export const ScheduleDrawer: React.FC<ScheduleDrawerProps> = ({
  appointmentDrawer,
  toggleAppointmentDrawer,
  setAppointmentDrawer,
  selectedAppointmentEvent,
  getSchedulesOfEmployees,
  setPendingAppointment,
  pendingAppointment,
}) => {
  return (
    <Drawer
      isOpen={appointmentDrawer.isOpen}
      setIsOpen={toggleAppointmentDrawer}
      backdrop={false}
      hideCloseBtn={appointmentDrawer.type === CrudAppointmentActions.READ}
      // hideCloseBtn
      onClose={() => setAppointmentDrawer({ isOpen: false, type: null })}
    >
      {appointmentDrawer.type === CrudAppointmentActions.READ && (
        <ReadAppointmentScreen
          selectedAppointmentEvent={selectedAppointmentEvent}
          getSchedulesOfEmployees={getSchedulesOfEmployees}
          toggleAppointmentDrawer={toggleAppointmentDrawer}
          setPendingAppointment={setPendingAppointment}
          closeDrawer={() =>
            setAppointmentDrawer({ isOpen: false, type: null })
          }
        />
      )}
      {appointmentDrawer.type === CrudAppointmentActions.CREATE && (
        <CreateAppointmentScreen
          pendingAppointment={pendingAppointment}
          setPendingAppointment={setPendingAppointment}
          getSchedulesOfEmployees={getSchedulesOfEmployees}
          toggleAppointmentDrawer={toggleAppointmentDrawer}
        />
      )}
    </Drawer>
  );
};
