import classNames from "classnames";
import * as React from "react";

export interface SectionHeaderProps {
  title: string;
  className?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  className,
}) => {
  return (
    <p className={classNames("text-xl font-semibold leading-7", className)}>
      {title}
    </p>
  );
};
