import * as React from "react";
import classnames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "@nextui-org/react";
import { useEffect, useRef } from "react";
import { AsIconButton } from "app/subframe";

export interface DrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  setIsOpen: (isOpen: boolean) => void;
  backdrop?: boolean;
  hideCloseBtn?: boolean;
  closeOnBackdropClick?: boolean;
  onClose?: () => void;
}
export const Drawer: React.FC<DrawerProps> = (props) => {
  const { isOpen, setIsOpen, children, hideCloseBtn = false, onClose } = props;

  const drawerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* Drawer Content */}
      <div
        ref={drawerRef}
        className={classnames(
          "fixed top-0 right-0 w-full max-w-xl shadow-dialog-shadow z-[300] h-[100dvh] bg-white transition-all duration-300 transform ease-in-out",
          {
            "translate-x-0": isOpen,
            "translate-x-full": !isOpen,
          }
        )}
      >
        <section
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={classnames(
            "w-full p-2 bg-white min-h-full shadow-dialog-shadow"
          )}
        >
          {!hideCloseBtn && (
            // <Button
            //   variant="light"
            //   className="cursor-pointer absolute top-4 right-4 p-1 rounded-sm w-6 h-6 z-[100] mobile:top-2 mobile:right-2"
            //   onPress={() => {
            //     setIsOpen(false);
            //     onClose?.();
            //   }}
            //   isIconOnly
            // >
            //   <XMarkIcon className="w-6 h-6" />
            // </Button>
            <AsIconButton
              variant="ghost"
              className="cursor-pointer absolute top-2 right-2 z-[100]"
              size="xs"
              onClick={() => {
                setIsOpen(false);
                onClose?.();
              }}
              leadingIcon="FeatherX"
            />
          )}
          {children}
        </section>
      </div>
    </>
  );
};
