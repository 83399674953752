import * as React from "react";
import {
  Button,
  Divider,
  Input,
  Select,
  SelectItem,
  Spinner,
} from "@nextui-org/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useBusinessServices } from "./useBusinessServices";
import { durationList } from "app/redux/businessServicesSlice";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import { find } from "lodash";
import { AddCategoryModal } from "./components/addCategoryModal/addCategoryModal";
import { AddServiceModal } from "./components/addServiceModal/addServiceModal";
import { Collapse } from "react-collapse";
import GrayTriangleSvg from "app/assets/icons/grayTriangle.svg";
import classNames from "classnames";

export interface BusinessServicesProps {}

export const BusinessServices: React.FC<BusinessServicesProps> = (props) => {
  const {
    isLoading,
    businessServices,
    onServicePress,
    selectedServiceParams,
    selectedService,
    onServiceNameSave,
    duration,
    setDuration,
    price,
    setPrice,
    onUpdateService,
    isDirty,
    isUpdating,
    onServiceCategoryNameSave,
    showAddCategoryModal,
    setShowAddCategoryModal,
    showAddServiceModal,
    setShowAddServiceModal,
    onAddServiceClick,
    serviceCategoryIdForNewService,
  } = useBusinessServices();

  const [openCategoryId, setOpenCategoryId] = React.useState<number | null>(
    null
  );

  if (isLoading) {
    return <Spinner size="lg" />;
  }

  return (
    <>
      <AddCategoryModal
        showAddCategoryModal={showAddCategoryModal}
        setShowAddCategoryModal={setShowAddCategoryModal}
      />
      <AddServiceModal
        showAddServiceModal={showAddServiceModal}
        setShowAddServiceModal={setShowAddServiceModal}
        serviceCategoryIdForNewService={serviceCategoryIdForNewService}
      />
      <div className="flex">
        <div className="border-r-1 h-screen w-full max-w-[240px] p-4 ">
          <Button
            variant="ghost"
            className="border-1 rounded border-black flex gap-2 w-full mb-3"
            onClick={() => setShowAddCategoryModal(true)}
          >
            <PlusIcon className="w-4 h-4 stroke-black" />
            <p className="text-body-bold font-body-bold text-neutral-900">
              Add Category
            </p>
          </Button>

          {businessServices.map((serviceCategory) => (
            <div key={serviceCategory.id}>
              <Button
                variant="ghost"
                className="w-full border-none flex justify-start rounded-sm mb-2"
                onClick={() =>
                  setOpenCategoryId(
                    openCategoryId === serviceCategory.id
                      ? null
                      : serviceCategory.id
                  )
                }
              >
                <div className="flex w-full items-center justify-between">
                  <p className="font-semibold text-sm text-[#3F3F46]">
                    {serviceCategory.name}
                  </p>
                  <img
                    src={GrayTriangleSvg}
                    alt="gray triangle"
                    className={classNames("transform duration-300", {
                      "rotate-180": openCategoryId === serviceCategory.id,
                    })}
                  />
                </div>
              </Button>
              <Collapse isOpened={openCategoryId === serviceCategory.id}>
                {serviceCategory.services.map((service: any) => (
                  <Button
                    variant="ghost"
                    key={service.id}
                    className="w-full border-none flex justify-start rounded-sm"
                    onClick={() =>
                      onServicePress({
                        serviceId: service.id,
                        categoryName: serviceCategory.name,
                        categoryId: serviceCategory.id,
                      })
                    }
                  >
                    <p>{service.title}</p>
                  </Button>
                ))}
                <Button
                  variant="ghost"
                  className="w-full border-none flex justify-start rounded-sm"
                  onClick={() => onAddServiceClick(serviceCategory.id)}
                >
                  <PlusIcon className="w-4 h-4 stroke-black stroke-[0.5px]" />
                  <p className="font-semibold ">Add Service</p>
                </Button>
              </Collapse>
            </div>
          ))}
        </div>
        {selectedService && (
          <div className="flex flex-col w-full p-4 items-center">
            <div className="flex flex-col gap-6 w-full max-w-[520px]">
              <InputEdit
                defaultValue={selectedServiceParams?.category || ""}
                name="name"
                valueClassName="text-body-bold font-body-bold text-neutral-900"
                onSave={(value) => onServiceCategoryNameSave(value)}
              />
              <div className="flex flex-col border rounded p-4 border-[#E4E4E7]">
                <div className="flex flex-col gap-4">
                  <InputEdit
                    defaultValue={selectedService.title}
                    name="title"
                    valueClassName="text-body-bold font-body-bold text-neutral-900"
                    onSave={onServiceNameSave}
                  />
                  <Divider className="mb-6" />
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex w-full justify-between items-center">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Duration
                    </p>
                    <Select
                      variant="bordered"
                      className="max-w-[120px] bg-transparent"
                      classNames={{
                        trigger: "border-[#F4F4F5] rounded shadow-sm border",
                      }}
                      value={duration.duration}
                      selectedKeys={[String(duration.duration)]}
                      onChange={(event) => {
                        setDuration({
                          duration: Number(event.target.value),
                          label: find(durationList, {
                            duration: Number(event.target.value),
                          }),
                        });
                      }}
                    >
                      {durationList.map(({ duration, label }) => (
                        <SelectItem key={String(duration)} value={duration}>
                          {label}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Price
                    </p>
                    <Input
                      variant="bordered"
                      startContent="$"
                      type="number"
                      classNames={{
                        base: "w-full max-w-[120px]",
                        inputWrapper:
                          "border-[#F4F4F5] rounded shadow-sm border",
                        input: "font-semibold",
                      }}
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="ghost"
                    className="w-[200px] rounded-sm self-end font-semibold border border-zinc-400"
                    onClick={() => onUpdateService(selectedService.id)}
                    isDisabled={!isDirty()}
                  >
                    {isUpdating ? <Spinner size="sm" /> : null}
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
