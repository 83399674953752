import * as React from "react";
import { motion } from "framer-motion"; // Import framer-motion
import { CreditCardOptions } from "./useCcCheckout";
import { AsButton, PaymentModalHeader } from "app/subframe";
import { SaleStatusConditionTypes } from "app/sales/useSales";
import { PaymentTypes } from "app/sales/components/openSaleScreen/components/paymentCheckout/usePaymentCheckout";
import { useBreakpoint } from "app/utils/useBreakpoint";
import classNames from "classnames";

export interface CcCheckoutOptionsProps {
  setCreditCardOption: (option: any) => void;
  setPayWithMode: (mode: any) => void;
  setShowQrCodeModal: (show: boolean) => void;
  saleStatusCondition: SaleStatusConditionTypes | null;
  onCloseDrawer: () => void;
}

export const CcCheckoutOptions: React.FC<CcCheckoutOptionsProps> = ({
  setCreditCardOption,
  setPayWithMode,
  setShowQrCodeModal,
  saleStatusCondition,
  onCloseDrawer,
}) => {
  const { isBelowSm } = useBreakpoint("sm");

  return (
    <>
      {isBelowSm ? (
        <>
          {isBelowSm && (
            <div className="fixed top-0 left-0 h-screen w-screen bg-[#171718]/80" />
          )}
          <motion.div
            className={classNames(
              "fixed bottom-0 left-0 flex h-96 flex-none flex-col items-center gap-6 rounded-t-large bg-white px-2 py-4 shadow-pop-up-shadow mobile:h-auto mobile:w-full"
            )}
            initial={{ y: 400, opacity: 0 }} // Start off-screen
            animate={{ y: 0, opacity: 1 }} // Slide up and fade in
            transition={{ duration: 0.5, ease: "easeInOut" }} // Animation settings
          >
            <PaymentModalHeader
              text={"Payment method"}
              buttonProps={{
                onClick: () => setPayWithMode(null),
              }}
            />
            <div className="flex w-full flex-col items-center justify-center gap-4">
              <AsButton
                className="h-12 w-full flex-none"
                variant="secondary"
                leadingIcon="FeatherLink"
                text="Pay with link"
                size="md"
                onClick={() =>
                  setCreditCardOption(CreditCardOptions.PAYMENT_LINK)
                }
              />
              <AsButton
                className="h-12 w-full flex-none"
                variant="secondary"
                leadingIcon="FeatherQrCode"
                trailingIcon={null}
                text="Pay with QR"
                size="md"
                onClick={() => setShowQrCodeModal(true)}
              />
              <AsButton
                className="h-12 w-full flex-none"
                variant="secondary"
                leadingIcon="FeatherSmartphone"
                trailingIcon={null}
                text="Pay on app"
                size="md"
                onClick={() => setCreditCardOption(CreditCardOptions.IN_APP)}
              />
              <AsButton
                className="h-12 w-full flex-none"
                variant="secondary"
                trailingIcon={null}
                text="Record a CC payment"
                size="md"
                onClick={() => setCreditCardOption(CreditCardOptions.PREPAID)}
              />
            </div>
          </motion.div>
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-center gap-4">
            <AsButton
              className="h-10 grow shrink-0 basis-0"
              variant="secondary"
              leadingIcon="FeatherLink"
              text="Pay with link"
              size="default"
              onClick={() =>
                setCreditCardOption(CreditCardOptions.PAYMENT_LINK)
              }
            />
            <AsButton
              className="h-10 grow shrink-0 basis-0"
              variant="secondary"
              leadingIcon="FeatherQrCode"
              text="Pay with QR"
              size="default"
              onClick={() => setShowQrCodeModal(true)}
            />
          </div>
          <div className="flex w-full items-center justify-center gap-4">
            <AsButton
              className="h-10 grow shrink-0 basis-0"
              variant="secondary"
              leadingIcon="FeatherSmartphone"
              text="Pay on app"
              size="default"
              onClick={() => setCreditCardOption(CreditCardOptions.IN_APP)}
            />
            {/* {!saleStatusCondition ? (
              <AsButton
                className="h-10 grow shrink-0 basis-0"
                variant="secondary"
                leadingIcon="FeatherSmartphone"
                text="Pay on app"
                size="default"
                onClick={() => setCreditCardOption(CreditCardOptions.IN_APP)}
              />
            ) : (
              <AsButton
                className="h-10 grow shrink-0 basis-0"
                variant="secondary"
                leadingIcon="FeatherBanknote"
                text="Cash"
                size="default"
                onClick={() => setPayWithMode(PaymentTypes.CASH)}
              />
            )} */}
            <AsButton
              className="h-10 grow shrink-0 basis-0"
              variant="secondary"
              text="Record a CC payment"
              size="default"
              onClick={() => setCreditCardOption(CreditCardOptions.PREPAID)}
            />
          </div>
        </>
      )}
    </>
  );
};
