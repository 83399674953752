import React, { useEffect } from "react";
import { format, isToday, subDays } from "date-fns";
import classNames from "classnames";
import { Header } from "./components/header/header";
import { ScheduleCard } from "app/subframe/components/ScheduleCard";
import { AsButton, TimeChip } from "app/subframe";
import { useMobileSchedule } from "./useMobileSchedule";
import { Collapse } from "react-collapse";
import GrayTriangleSvg from "app/assets/icons/grayTriangle.svg";
import BigPlusSvg from "app/assets/icons/bigPlus.svg";
import { CardAccordion } from "app/subframe/components/CardAccordion";
import { SwipeableWeekView } from "app/schedules/mobileSchedule/components/swipeableWeekView/swipeableWeekView";

export interface MobileScheduleProps {
  selectedEmployee: any;
  setSelectedEmployee: (employee: any) => void;
  appointments: any[];
  date: Date;
  toggleAppointmentDrawer: any;
  onAppointmentSelect: any;
  createNewPendingAppointment: any;
  currentShopTime: Date;
}

export const MobileSchedule: React.FC<MobileScheduleProps> = ({
  selectedEmployee,
  setSelectedEmployee,
  appointments,
  date,
  toggleAppointmentDrawer,
  onAppointmentSelect,
  createNewPendingAppointment,
  currentShopTime,
}) => {
  const {
    selectedDate,
    currentWeekStart,
    onDateChange,
    onWeekChange,
    selectedEmployeeAppointments,
    timeSlots,
    expandedSlots,
    toggleCollapse,
    availabilityRanges,
    onNewAppointmentInit,
    shop,
  } = useMobileSchedule({
    selectedEmployee,
    appointments,
    date, // Pass date prop here
    createNewPendingAppointment,
    toggleAppointmentDrawer,
  });

  return (
    <div className="container max-w-none flex w-full flex-col items-center gap-4 bg-millet-200 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-4 mobile:bg-millet-100- mobile:px-0 mobile:py-0">
      {/* Header */}
      <Header
        selectedEmployee={selectedEmployee}
        setSelectedEmployee={setSelectedEmployee}
      />

      {/* Week View */}
      <div className="flex w-full items-start pt-4">
        {/* Year and Month Section */}
        <div className="flex flex-col items-center px-4">
          <span className="text-sm text-neutral-700">
            {format(currentWeekStart, "yyyy")}
          </span>
          <span className="text-md font-semibold mt-1">
            {format(currentWeekStart, "MMM").toUpperCase()}
          </span>
        </div>

        {/* Swipeable Week View */}
        <SwipeableWeekView
          currentWeekStart={currentWeekStart}
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          onWeekChange={onWeekChange}
        />
      </div>

      {/* Schedule Content */}
      <div
        className={classNames(
          "flex w-112 grow duration-500 shrink-0 basis-0 flex-col items-start gap-4 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:px-2 pb-40"
        )}
      >
        {selectedDate >= subDays(currentShopTime, 1) && (
          <CardAccordion
            className="w-full"
            text={`Available times ${
              isToday(selectedDate)
                ? "today"
                : `for ${format(selectedDate, "MMM dd")}`
            }:`}
          >
            {availabilityRanges.map((slot, index) => (
              <TimeChip
                time={`${format(slot.startTime, "h:mm a")} - ${format(
                  slot.endTime,
                  "h:mm a"
                )}`}
                className="w-full"
                key={index}
                onClick={() =>
                  onNewAppointmentInit({
                    startTime: slot.startTime,
                    endTime: slot.endTime,
                  })
                }
              />
            ))}
          </CardAccordion>
        )}
        {/* Time Slots and Appointments */}
        {timeSlots.length ? (
          timeSlots.map((slot) => {
            const isExpanded = expandedSlots.includes(slot.hour);
            const appointmentsInSlot = selectedEmployeeAppointments.filter(
              (appointment: any) => {
                return (
                  appointment.start < slot.slotEndTime &&
                  appointment.end > slot.slotStartTime
                );
              }
            );
            return (
              <div
                key={slot.hour}
                className="flex w-full flex-col items-start gap-2"
              >
                {/* Time Slot Header */}
                <div
                  className="flex w-full items-center gap-2 cursor-pointer"
                  onClick={() => toggleCollapse(slot.hour)}
                >
                  <div className="flex w-full items-center justify-center gap-2 mobile:flex-row mobile:gap-2">
                    <span className="h-6 w-12 flex-none text-body-bold font-body-bold text-neutral-700">
                      {slot.timeLabel}
                    </span>
                    <div className="flex h-px w-full flex-col items-center gap-2 bg-neutral-50" />
                  </div>
                  <img
                    className={classNames("flex-none transform", {
                      "rotate-180": isExpanded,
                      "rotate-90": !isExpanded,
                    })}
                    src={GrayTriangleSvg}
                    alt="Toggle"
                  />
                </div>
                {/* Appointments */}
                <Collapse isOpened={isExpanded}>
                  {/* Collapsible content */}
                  {appointmentsInSlot.map((appointment: any) => {
                    const isAppointmentInCurrentShop =
                      appointment.shop.id === shop?.id;
                    return (
                      <div
                        key={appointment.id}
                        className="flex w-[calc(100vw-16px)] flex-col items-start gap-2 pl-14 mb-2"
                      >
                        <ScheduleCard
                          service={appointment.title}
                          time={`${format(
                            new Date(appointment.start),
                            "h:mm a"
                          )} - ${format(new Date(appointment.end), "h:mm a")}`}
                          clientName={appointment.client.name}
                          clientAvatar={appointment.client.avatar}
                          variant={
                            isAppointmentInCurrentShop ? "default" : "break"
                          }
                          locationName={
                            shop?.id !== appointment.shop.id
                              ? appointment.shop.name
                              : null
                          }
                          onClick={() => onAppointmentSelect(appointment)}
                        />
                      </div>
                    );
                  })}
                </Collapse>
              </div>
            );
          })
        ) : (
          <p>
            {selectedEmployee?.name} is off {format(selectedDate, "MMM dd")}
          </p>
        )}
      </div>

      {/* Add Appointment Button */}
      <AsButton
        className="h-14 w-14 flex fixed bottom-[88px] right-4"
        variant="brand"
        trailingIcon={null}
        text=""
        size="md"
        loader={false}
        disabled={false}
        disabledSecondary={false}
        shadow={true}
        onClick={() => onNewAppointmentInit({})}
      >
        <img src={BigPlusSvg} className="scale-[1.5]" alt="Add" />
      </AsButton>
    </div>
  );
};
