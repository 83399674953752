export enum Path {
  HOME = "/",
  SIGNUP = "/signup",
  LOGIN = "/login",
  SETTINGS = "/settings",
  BUSINESS_SERVICES = "/business-services",
  ONBOARDING = "/onboarding/:step",
  STAFF = "/staff",
  SALES = "/sales",
  CLIENTS = "/clients",
  REPORTS = "/reports",
  ACCEPT_INVITE = "/accept-invite",
  USER = "/user",
}
